import React, { useState, useEffect } from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import images from "../utils/imageImports";
import { Link } from "react-router-dom";
import { AXIOS_BASE_URL } from "../actions/types";
import { getCards } from "../actions/cards";
import { connect } from "react-redux";
import PropTypes from "prop-types";
function HomePage({ getCards, cards: { cards } }) {
  const [totalCards, setTotalCards] = useState([]);

  useEffect(() => {
    getCards();
  }, []);
  useEffect(() => {
    setTotalCards(cards.map((card) => ({ ...card, background: "#000000" })));
  }, [cards]);

  function getTopPosition(card_type) {
    switch (card_type) {
      case "1":
        return "10px"; // Adjust as needed

      case "2":
        return " 22px"; // Adjust as needed
      default:
        return "50%"; // Default to center if placement is not defined
    }
  }

  function getLeftPosition(card_type) {
    switch (card_type) {
      case "1":
      case "3":
        return "calc(100% - 286px)"; // Adjust as needed
      case "2":
      case "4":
        return "105px"; // Adjust as needed
      default:
        return "50%"; // Default to center if placement is not defined
    }
  }
  return (
    <>
      <Header />
      <main>
        <section id="main">
          <div className="container position-relative">
            <div className="d-none d-sm-block">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-10">
                  <img
                    src={images.bannerbg}
                    className="img-fluid w-100"
                    alt="Banner"
                  />
                </div>
              </div>
              <div className="row py-1">
                <div className="col-md-6">
                  <div className="headingtxt">
                    <p className="yellowtext mb-1">#StartSmallGrowBig</p>
                    <h1>
                      Embark on the <br />
                      Journey of growth <br />
                      <span>
                        <img
                          src={images.startHere}
                          className="img-fluid"
                          alt="Start"
                        />
                      </span>
                    </h1>
                    <h6 className="mb-5 text-start w-50 mt-3">
                      Setup your mini profile, mini website or ministore. Share
                      with friends and customers.
                    </h6>

                    <div className="d-flex justify-content-start align-items-center pt-4">
                      <p className="me-3">
                        <a href="/register" className="probtn">
                          Create your profile
                        </a>
                      </p>
                      <p>
                        <a
                          href="/login"
                          className="probtn"
                          // style={{ backgroundColor: "#FFB400" }}
                        >
                          Login To Profile
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
              </div>
              <div className="row mt-3 mb-5">
                <h6> Shop Vyldfyre </h6>
                {cards &&
                  totalCards &&
                  totalCards.map((card, index) => (
                    <>
                      <div key={index} className="col-md-3 pt-3 ">
                        <div className="uploadcard mb-2 me-3">
                          <Link
                            to={`/vyldfyrefrontcard/${encodeURIComponent(
                              card.background
                            )}/${card.id}`}
                            className="text-light"
                          >
                            <div
                              className="d-flex justify-content-center align-items-center p-2 "
                              style={{
                                position: "relative",
                                // background: card.background,
                              }}
                            >
                              <img
                                src={`${AXIOS_BASE_URL}uploads/${card.background_image}`}
                                className=" d-flex flex-column align-items-center justify-content-center img-fluid"
                              />
                              <div
                                className="position-absolute"
                                style={{
                                  zIndex: 10,
                                  top: getTopPosition(card.card_type),
                                  right:
                                    ["1", "3"].includes(card.card_type) &&
                                    getLeftPosition(card.nfc_icon_placement), // Right for 1 & 3, otherwise auto
                                  left:
                                    ["2", "4"].includes(card.card_type) &&
                                    getLeftPosition(card.card_type), // Left for 2 & 4, otherwise auto
                                  display: "inline-flex",
                                  alignItems: "center",
                                  whiteSpace: "nowrap", // Prevent text from wrapping
                                }}
                              >
                                {(card.card_type === "1" ||
                                  card.card_type === "2") && (
                                  <>
                                    {/* Wrap text in a span */}
                                    <img
                                      src={images.nfcsymbol}
                                      style={{
                                        width: "20px",
                                        marginLeft: "5px",
                                        verticalAlign: "middle",
                                      }} // Adjust margin and vertical alignment
                                      alt="NFC Symbol"
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </Link>
                          {/* <div className="row ms-0">
                            {card.colors.map((color, id) => (
                              <div
                                key={id}
                                onClick={() => {
                                  handleColor(color, index);
                                }}
                                className="colorboxpick col-md-1"
                                style={{ background: color }}
                              ></div>
                            ))}
                          </div> */}
                        </div>
                        <span className="text-white">{card.card_name}</span>
                        <p className="mb-3"> one from ₹ {card.card_price}</p>
                      </div>
                    </>
                  ))}
              </div>
            </div>

            <div className="d-md-none">
              <div className="row mt-5">
                <div className="col-md-12 my-5">
                  <p className="yellowtext mb-1">#StartSmallGrowBig</p>
                  <h1 className="mb-5">
                    Embark on the <br />
                    Journey of growth <br />
                    <span>
                      <img
                        src={images.startHere}
                        className="img-fluid"
                        alt="Start"
                      />
                    </span>
                  </h1>
                  <h6 className="mb-5 text-center mt-3">
                    Setup your mini profile, mini website or ministore. Share
                    with friends and customers.
                  </h6>
                  <p className="text-center mt-5">
                    <a href="/register" className="probtn">
                      Create your profile
                    </a>
                  </p>
                </div>
                <div className="col-md-12 mt-3">
                  <img
                    src={images.mobileBanner}
                    className="img-fluid w-100"
                    alt="Mobile Banner"
                  />
                </div>
              </div>
              <p>Shop Vyldfyre</p>
              <div className="col-md-12"></div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

HomePage.propTypes = {
  getCards: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  cards: state.cards,
});
export default connect(mapStateToProps, { getCards })(HomePage);
