import axios from "axios";
import { GET_PROFILE,PROFILE_ERROR ,ORDER_DETAILS, ORDERS} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { setAlert } from "./setAlert";


export const checkout = (obj)=> async dispatch =>{
   
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    const body = obj;
    try {
        const res =  await axios.post(`api/cart/checkout`, body  )
        console.log(res)
        dispatch(setAlert(res.data.message, 'success'))

        dispatch({
            type: ORDERS,
            payload:res.data.data.result,
        });
    } catch (err) {
        console.log(err)
        dispatch({
            type: PROFILE_ERROR,
            payload: err.message,
        });
    }
}



export const getOrderDetails = (id) => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res = await axios.get(`/api/cart/checkout/order/${id}`);
        dispatch(setAlert('Order details fetched successfully', 'success'));

        dispatch({
            type: ORDER_DETAILS,
            payload: res.data.data.result, // Assuming result is the array of order details
        });
        return res.data.data.result;
    } catch (err) {
        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status },
        });
    }
};
