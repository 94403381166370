import React,{useEffect} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faQrcode,
  faFillDrip,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

import { faKeyboard, faEye } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from 'react-router-dom';

function CardEditLayout({card, background}) {
  const navigate = useNavigate();
  console.log(card);
  console.log(background);

  const handleNextClick = () => {
    // Assuming you have logic to determine selected card and background
    const selectedCard = card; // Example: card.id
    const selectedBackground = background; // Example: background.url
    
    // Saving data to localStorage
    // localStorage.setItem('selectedCard', JSON.stringify(selectedCard));
    localStorage.setItem('selectedBackground', JSON.stringify(selectedBackground));
  };
  const NavigateToPreviosPage = () => {
console.log("previos");
  }
  return (
<>
<div className="col-md-12">
              <div className="border-bottom border-light">
                <div className="row align-items-center">
                  <div className="col">
                    <ul className="nav justify-content-start gap-4">
                      <li>
                      <Link className="button btn-secondary px-3" onClick={() => navigate(-1)}>
                          Back
                        </Link>
                      </li>
                      <li>
                        <span>{card.card_name}</span>
                      </li>
                      
                    </ul>
                  </div>
                  <div className="col text-end">
                    <ul className="nav justify-content-end gap-4">
                      <li>
                        <span>
                            <Link className='text-light' to="/vyldfyre-card">
                          <FontAwesomeIcon icon={faEdit} />
                          Change Template
                          </Link>
                        </span>
                      </li>
                      <li>
                        <Link className="button btn-secondary px-3" onClick={handleNextClick} to="/confirmcard">
                          Next
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
           
           
</>
  )
}

export default CardEditLayout