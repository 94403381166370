
import React , {useState} from 'react'
import AdminLoginHeader from '../../components/layout/AdminLoginHeader';
import Footer from '../../components/layout/Footer';
import images from '../../utils/imageImports';
import { Link } from 'react-router-dom';
import { adminregister } from '../../actions/AdminAuth';
import { useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import PasswordVisibilityToggler from '../../utils/passwordVisibilityToggler';
function AdminSignUp({adminregister}) {
    const navigate = useNavigate()
    const [passwordInputType, toggleIcon] = PasswordVisibilityToggler();
const [user, setuser] = useState({
    email:"",
    username:'',
    password:"",
    confirmPassword:"",
})
const handleChange =(e)=>{
   const {name, value} = e.target;
   setuser({...user, [name] : value })

}
const onSubmit = async (e)=>{
   
    e.preventDefault();
   if(user.password === user.confirmPassword){
    user.confirmPassword = undefined;
    await adminregister(user);
    navigate("/admin/login");

   }else{
    console.log("password and confirm password should be same")
   }
}  
  return (
    <div>
<AdminLoginHeader />
        <section id="main">
<div className="container position-relative">
    <div className="row">
        <div className="col-md-4 mt-3">
            <form className="mt-5">
            <div className="welcard p-4 mt-5">
                <p className="mb-5 mt-2"><img src={images.loginicon} alt="" /></p>
                <h6>Welcome to VYLDFYRE</h6>
                <p>Please sign in or sign up below.</p>
                    <div className="text-start mt-4">
                      <p className="mb-4"><input type="email" className="form-control text-light" name="email" value={user.email} onChange={handleChange} placeholder="Email" required /></p>
                      <p className="mb-4"><input type="text" className="form-control text-light" name="username" value={user.username} onChange={handleChange} placeholder="username" required /></p>
                      <p className="mb-4"><input type={passwordInputType} className="form-control text-light" name="password" value={user.password} onChange={handleChange} placeholder="password" required />
                      
                      </p>
                      <p><input type={passwordInputType} className="form-control text-light" name="confirmPassword" value={user.confirmPassword} onChange={handleChange} placeholder="ConfirmPassword" required />
                      <span
                        className="passwordToggle"
                        style={{ color: "white", top:"220px" }}
                      >
                        {toggleIcon}
                      </span>
                      </p>

                      <Link onClick={onSubmit} className="loginbtn my-4">Sign Up</Link>
                    </div>
            </div>
            {/* <div className="googlecard p-4">
                    <a className="googlebtn my-2"><img src={images.googleicon} className="pe-2" alt="" /> Sign Up in
                      with Google</a>
            </div> */}
            <p className="text-center pt-4 mb-5">Already have an account? <Link to="/admin/login" className="linkyellow1">Sign In</Link></p>
            </form>
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-7 dispnone">
            <img src={images.innerbg} className="img-fluid w-100" alt="" />
        </div>
     </div>

</div>
</section>

        <Footer />
    </div>
  )
}
AdminSignUp.propTypes = {
    adminregister: PropTypes.func.isRequired,
   
    // setAlert: PropTypes.func.isRequired,
  };
  
  export default connect(null, { adminregister,  })(AdminSignUp);
