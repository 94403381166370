import React from 'react'
import images from '../../utils/imageImports'
import { Link } from 'react-router-dom'
function Header() {
  return (
   <>
   <header>
        <div className="container">
          <div className="d-none d-sm-block">
            <div className="row pt-4">
              <div className="col-md-6">
                <Link to="/">
                  <img src={images.logo} className="img-fluid" width="208" alt="Vyldfyre Logo" />
                </Link>
              </div>
              <div className="col-md-6 text-end">
                <Link to="/vyldfyre-card" className="signbtn" 
                          style={{ backgroundColor: "#FFB400" }}>
                  Shop Vyldfyre
                </Link>
              </div>
            </div>
          </div>
          <div className="d-md-none">
            <div className="row">
              <div className="col-md-12 pt-3">
                <Link to="/">
                  <img src={images.logo} width="153" className="img-fluid" alt="Vyldfyre Logo" />
                </Link>
                <Link to="/login" className="signbtn">
                  Sign In
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
      </>
      
  )
}

export default Header