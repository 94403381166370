import { combineReducers } from "redux";
import auth from "./auth";
import profile from "./profile";
import socialDetails from "./socialDetails";
import gallery from "./gallery";
import alert from "./alert";
import cards from "./cards";
import orders from "./orders";
import adminauth from "./adminauth"

export default combineReducers({
  alert,
  auth,
  profile,
  socialDetails,
  gallery,
  cards,
  orders,
  adminauth,
});
