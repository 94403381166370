import React, { useRef, useEffect, useState } from "react";
import { fabric } from "fabric";
import { Link } from "react-router-dom";
import images from "../../../utils/imageImports";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faQrcode,
  faFillDrip,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faKeyboard } from "@fortawesome/free-regular-svg-icons";
import { TwitterPicker } from "react-color";
import axios from "axios";
import {
  getCircleLeftPosition,
  getCircleTopPosition,
} from "../../../utils/handlecapture";
import { AXIOS_BASE_URL } from "../../../actions/types";

const BandEditor = ({ background, card, flag }) => {
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const colorPickerRef = useRef(null);
  const [color, setColor] = useState("#FFFFFF");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedCard, setSelectedCard] = useState(card);
  const [backgroundColor, setBackgroundColor] = useState(background);
  const [canvas, setCanvas] = useState(null);
  const [textObject, setTextObject] = useState(null);
  const [imageObject, setImageObject] = useState(null);
  const [image, setImage] = useState("");
  const [imgWidth, setImgWidth] = useState(null);
  const [text, setText] = useState("Type Here ...");

  useEffect(() => {
    const existingCard = JSON.parse(localStorage.getItem("selectedCard"));
    if (existingCard) {
      setSelectedCard(existingCard);
    } else {
      setSelectedCard(card);
    }
  }, []);

  useEffect(() => {
    const newCanvas = new fabric.Canvas("c", {
      backgroundColor: backgroundColor,
      selection: true,
    });
    fabric.Object.prototype.transparentCorners = false;
    newCanvas.preserveObjectStacking = true;


    const canvasWidth = newCanvas.width;
const canvasHeight = newCanvas.height;
const radius = Math.min(canvasWidth, canvasHeight) / 2; // Adjust the multiplier as needed

console.log('Canvas Width:', canvasWidth);
console.log('Canvas Height:', canvasHeight);
console.log('Calculated Radius:', radius);
newCanvas.setWidth(640);
    newCanvas.setHeight(365);
const circleClipPath = new fabric.Circle({
  radius: "182.5",
  left: "320" ,
  top: "182.5",
  originX: "center",
  originY: "center",
});
    newCanvas.clipPath = circleClipPath;
    setCanvas(newCanvas);
    

    const savedObject = JSON.parse(localStorage.getItem("circletextbox"));
    const defaultTextObject = {
      text: text,
      left: 150,
      top: 50,
      width: 150,
      fontSize: 22,
      fill: color,
      visible: false,
    };

    if (flag === "front") {
      const newTextObject = new fabric.Textbox(
        savedObject ? savedObject.text : defaultTextObject.text,
        {
          left: savedObject ? savedObject.left : defaultTextObject.left,
          top: savedObject ? savedObject.top : defaultTextObject.top,
          width: savedObject ? savedObject.width : defaultTextObject.width,
          fontSize: savedObject
            ? savedObject.fontSize
            : defaultTextObject.fontSize,
          fill: savedObject ? savedObject.fill : defaultTextObject.fill,
          visible: savedObject
            ? savedObject.visible
            : defaultTextObject.visible,
        }
      );

      newCanvas.add(newTextObject);
      newCanvas.moveTo(newTextObject, 10);
      setTextObject(newTextObject);
    }
    if (flag === "front" && image) {
      if (typeof image === "string") {
        const imgUrl = image;
        fabric.Image.fromURL(imgUrl, function(img) {
          img.scaleToWidth(imgWidth ? imgWidth : 400).set({
            left: 100,
            top: 60,
            angle: 0,
            selectable: true,
            visible: true,
          });
          newCanvas.add(img);
          setImageObject(img);
        });
      } else if (image instanceof File) {
        const reader = new FileReader();
        reader.onload = function(event) {
          const dataUrl = event.target.result;
          fabric.Image.fromURL(dataUrl, function(img) {
            img.scaleToWidth(imgWidth ? imgWidth : 400).set({
              left: 100,
              top: 50,
              angle: 0,
              selectable: true,
              visible: true,
            });
            newCanvas.add(img);
            setImageObject(img);
          });
        };
        reader.readAsDataURL(image);
      }
    }
    return () => {
      newCanvas.dispose();
    };
  }, [backgroundColor]);

  useEffect(() => {
    if (canvas && flag === "front" && image) {
      if (typeof image === "string") {
        fabric.Image.fromURL(image, (img) => {
          img.scaleToWidth(imgWidth || 400).set({
            left: 100,
            top: 60,
            angle: 0,
            selectable: true,
            visible: true,
          });
          canvas.add(img);
          setImageObject(img);
        });
      } else if (image instanceof File) {
        const reader = new FileReader();
        reader.onload = (event) => {
          fabric.Image.fromURL(event.target.result, (img) => {
            img.scaleToWidth(imgWidth || 400).set({
              left: 100,
              top: 50,
              angle: 0,
              selectable: true,
              visible: true,
            });
            canvas.add(img);
            setImageObject(img);
          });
        };
        reader.readAsDataURL(image);
      }
    }
  }, [canvas, image, flag]);

  useEffect(() => {
    const savedImageObject = JSON.parse(localStorage.getItem("circleimageObject"));
    if (savedImageObject && canvas && flag === "front") {
      fabric.Image.fromURL(savedImageObject.src, function(img) {
        img.set({
          left: savedImageObject.left,
          top: savedImageObject.top,
          angle: savedImageObject.angle,
          scaleX: savedImageObject.scaleX,
          scaleY: savedImageObject.scaleY,
          selectable: true,
          visible: savedImageObject.visible,
        });
        canvas.add(img);
        setImageObject(img);
      });
    }
  }, [canvas, flag]);

  useEffect(() => {
    if (canvas && textObject) {
      const handleTextChange = () => {
        const newText = textObject.text;
        setText(newText);
        try {
          localStorage.setItem("circletextbox", JSON.stringify(textObject));
          console.log("Text saved to localStorage:", newText);
        } catch (error) {
          console.error("Error saving text to localStorage:", error);
        }
      };

      const handleTextModification = () => {
        try {
          localStorage.setItem("circletextbox", JSON.stringify(textObject));
          console.log("Text object position/size saved to localStorage");
        } catch (error) {
          console.error(
            "Error saving text object position/size to localStorage:",
            error
          );
        }
      };

      textObject.on("changed", handleTextChange);
      textObject.on("moving", handleTextModification);
      textObject.on("scaling", handleTextModification);

      return () => {
        textObject.off("changed", handleTextChange);
        textObject.off("moving", handleTextModification);
        textObject.off("scaling", handleTextModification);
      };
    }
  }, [textObject, canvas]);

  useEffect(() => {
    if (canvas && imageObject) {
      const handleImageModification = () => {
        try {
          localStorage.setItem("circleimageObject", JSON.stringify(imageObject));
          console.log("Image object saved to localStorage");
        } catch (error) {
          console.error("Error saving image object to localStorage:", error);
        }
      };

      imageObject.on("modified", handleImageModification);

      return () => {
        imageObject.off("modified", handleImageModification);
      };
    }
  }, [imageObject, canvas]);

  const handleColorChange = (updatedColor) => {
    setColor(updatedColor.hex);
    if (textObject) {
      textObject.set("fill", updatedColor.hex);
      canvas.renderAll();
      localStorage.setItem("circletextbox", JSON.stringify(textObject));
    }
  };
  const handleClickOutside = (event) => {
    if (
      colorPickerRef.current &&
      !colorPickerRef.current.contains(event.target)
    ) {
      setShowColorPicker(false);
    }
  };

  useEffect(() => {
    if (showColorPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showColorPicker]);
  const addOrUpdateTextbox = () => {
    if (textObject) {
      textObject.visible = !textObject.visible;
      textObject.visible ? canvas.add(textObject) : canvas.remove(textObject);
      canvas.requestRenderAll();
      localStorage.setItem("circletextbox", JSON.stringify(textObject));
    } else {
      const newTextObject = new fabric.Textbox("Type Here ...", {
        left: 300,
        top: 150,
        width: 150,
        fontSize: 20,
        fill: color,
      });
      setTextObject(newTextObject);
      canvas.add(newTextObject);
      localStorage.setItem("circletextbox", JSON.stringify(newTextObject));
    }
  };

  const addOrUpdateImage = () => {
    if (imageObject) {
      imageObject.visible = !imageObject.visible;
      canvas.requestRenderAll();
      localStorage.setItem("circleimageObject", JSON.stringify(imageObject));
    } else {
      const reader = new FileReader();
      reader.onload = (event) => {
        fabric.Image.fromURL(event.target.result, (img) => {
          img.scaleToWidth(imgWidth || 400).set({
            left: 100,
            top: 50,
            angle: 0,
            selectable: true,
            visible: true,
          });
          canvas.add(img);
          setImageObject(img);
        });
      };
      if (image instanceof File) {
        reader.readAsDataURL(image);
      } else {
        fabric.Image.fromURL(image, (img) => {
          img.scaleToWidth(imgWidth || 400).set({
            left: 100,
            top: 60,
            angle: 0,
            selectable: true,
            visible: true,
          });
          canvas.add(img);
          setImageObject(img);
        });
      }
    }
  };

  const deleteImage = () => {
    if (imageObject) {
      canvas.remove(imageObject);
      setImageObject(null);
      setImage(null);
      localStorage.removeItem("circleimageObject");
      canvas.requestRenderAll();
    }
  };

  const deleteText = () => {
    if (textObject) {
      canvas.remove(textObject);
      setTextObject(null);
      localStorage.removeItem("circletextbox");
      canvas.requestRenderAll();
    }
  };

  const handlePlacementChange = async (e) => {
    const placement = e.target.value;
    const updatedCard = { ...selectedCard, nfc_icon_placement: placement };
  
    setSelectedCard(updatedCard);
    localStorage.setItem('selectedCard', JSON.stringify(updatedCard));

    try {
      const id = selectedCard.id;
      const response = await axios.put("api/getcards/changeplacement", {
        id,
        newPlacement: placement,
      });
      console.log(`Success: ${response.data.message}`);
    } catch (error) {
      if (error.response) {
        console.log(`Error: ${error.response.data.error}`);
      } else {
        console.log("Error: Something went wrong");
      }
    }
  };

  return (
    <>
      <div className="col-md-2">
        {!image && (
          <input
            type="file"
            ref={inputRef}
            style={{ display: "none" }}
            onChange={(e) => {
              const file = e.target.files[0];
              setImage(file);
            }}
          />
        )}
        <ul className="navbar-nav justify-content-center p-2 mt-5">
          <li className="p-3">
            <FontAwesomeIcon
              size="2x"
              onClick={addOrUpdateTextbox}
              icon={faKeyboard}
            />
            <h6> ADD TEXT</h6>
          </li>
          {/* <li className="p-3">
            <label htmlFor="file-upload" className="text-light">
              <FontAwesomeIcon
                size="2x"
                icon={faImage}
                onClick={addOrUpdateImage}
              />
              {!image && (
                <input
                  id="file-upload"
                  type="file"
                  style={{ display: "none" }}
                  ref={inputRef}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setImage(file);
                  }}
                />
              )}
            </label>
            <h6>ADD IMAGE </h6>
          </li> */}
          <li className="p-3">
            <FontAwesomeIcon
              size="2x"
              icon={faFillDrip}
              onClick={() => setShowColorPicker(!showColorPicker)}
            />
            <h6>CHANGE TEXT COLOR </h6>
          </li>
        </ul>
        {showColorPicker && (
          <div ref={colorPickerRef} onMouseDown={(e) => e.stopPropagation()}>
            <TwitterPicker
              color={color}
              className="color-selector position-absolute"
              onChange={handleColorChange}
            />
          </div>
        )}
      </div>
      <div className="col-md-8" style={{ position: "relative" }}>
        <div
          ref={containerRef}
          className="canvas-container d-flex align-content-center justify-content-center mt-5"
        >
          <canvas id="c"></canvas>

          {flag === "front" && (
            <img
              src={`${AXIOS_BASE_URL}uploads/${selectedCard.background_image}`}
              className="d-flex flex-column align-items-center justify-content-center z-index-1 position-absolute"
              style={{ top: "11%", width: "40%" }}
            />
          )}
          {/* {flag === "front" && card && (
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                top: getCircleTopPosition(selectedCard.nfc_icon_placement),
                right:
                  ["1", "3"].includes(selectedCard.nfc_icon_placement) &&
                  getCircleLeftPosition(selectedCard.nfc_icon_placement),
                left:
                  ["2", "4"].includes(selectedCard.nfc_icon_placement) &&
                  getCircleLeftPosition(selectedCard.nfc_icon_placement),
                display: "inline-flex",
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
            >
              <div className="nfc-icon-placement">
                <img src={images.nfcsymbol} style={{ width: "33px" }} />
              </div>
              <div style={{ position: "relative", minWidth: 0, flex: 1 }}>
                <input
                className="form-control-plaintext"
                value={selectedCard && selectedCard.placeholder_text}
                name="placeholder_text"
                onChange={(e) => {
                  setSelectedCard({
                    ...selectedCard,
                    placeholder_text: e.target.value,
                  });
                }}
                dir="rtl"
                ref={inputRef}
              />
              </div>
            </div>
          )} */}
        </div>
        <div className=" row mt-3">
          {/* <div className="col-md-4" style={{ marginLeft: "95px" }}>
            <div className="form-item mb-3 ms-3 d-flex justify-content-start ">
              <select
                id="select"
                className="dropdown"
                name="nfcIconPlacement"
                value={selectedCard.nfc_icon_placement}
                onChange={handlePlacementChange}
                autoComplete="off"
                required
              >
                <option value="1">Top Right</option>
                <option value="2">Top Left</option>
                <option value="3">Bottom Right</option>
                <option value="4">Bottom Left</option>
              </select>
              <label htmlFor="select">NFC icon placement</label>
            </div>
          </div> */}
          <div className=" col-md-5 d-flex justify-content-end  mt-3">
            <Link className="linkyellow me-3 " onClick={deleteText}>
              Delete Text
            </Link>
            {/* <Link className="linkyellow" onClick={deleteImage}>
              Delete Image
            </Link> */}
          </div>
        </div>
        <div className="vertical-rule">
          <p>5.88 CM</p>
        </div>
        <div className="horizontal-rule">
          <p>9.02 CM</p>
        </div>
      </div>
    </>
  );
};

export default BandEditor;
