import React from 'react'
import ShopHeader from '../../layout/ShopHeader';
import Footer from '../../layout/Footer';
function VyldfyreBands() {
  return (
    <div>
      <ShopHeader />
      <section id="user" className="dashboard">
        <div className="container mb-5">
          <div className="row my-5 ">
            <div className="col-md-10">
              <h1>VYLDFYRE NFC Bands</h1>
              <p className="yellowtext">One from ₹750.00</p>
              <div className="row"></div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Footer />
    </div>
  )
}

export default VyldfyreBands