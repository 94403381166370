import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';

const GenerateQRCode = ({ val , flag }) => {
  const [qr, setQr] = useState('');
  const [selectedPlatform, setSelectedPlatform] = useState(val); 
console.log(val);
useEffect(()=>{
  const socialDetails = JSON.parse(localStorage.getItem("socialDetails"));
setSelectedPlatform(socialDetails);
},[])
  const generateQRCode = async (url) => {
    try {
      console.log(url)
      localStorage.setItem("socialDetails",JSON.stringify(val))
      const qrUrl = await QRCode.toDataURL(url, {
        width: 800,
        margin: 2,
        color: {
          dark: '#335383FF',
          light: '#EEEEEEFF',
        },
      });
      setQr(qrUrl);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    if (val.socialmedia_link) {
      generateQRCode(val.socialmedia_link);
    }
  }, [val]);

  return (
    <div>
      {qr && flag=="front" && <img src={qr} className="qrcode" alt="Generated QR Code" /> }
    </div>
  );
};

export default GenerateQRCode;
