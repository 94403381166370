import React, { useRef, useEffect, useState } from "react";
import { fabric } from "fabric";
import { Link } from "react-router-dom";
import images from "../../../utils/imageImports";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faQrcode,
  faFillDrip,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Form, Row, Col } from "react-bootstrap";

import { faKeyboard } from "@fortawesome/free-regular-svg-icons";
import { TwitterPicker } from "react-color";
import {
  getSdLeftPosition,
  getSdTopPosition,
} from "../../../utils/handlecapture";
import axios from "axios";
import GenerateQRCode from "../../../utils/GenerateQr";
import SelectedSocialPlatform from "../../../utils/SelectedSocialPlatform";
function SocialDetailCardEditor({ background, card, flag }) {
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const colorPickerRef = useRef(null);
  const [color, setColor] = useState("#FFFFFF");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedCard, setSelectedCard] = useState(card);
  const [backgroundColor, setBackgroundColor] = useState(background);
  const [canvasWidth, setCanvasWidth] = useState(360);
  const [canvasHeight, setCanvasHeight] = useState(640);
  const [text, setText] = useState("Type Here ...");
  const [canvas, setCanvas] = useState(null);
  const [textObject, setTextObject] = useState(null);
  const [imageObject, setImageObject] = useState(null);
  const [image, setImage] = useState("");
  const [dataImg, setDataImg] = useState(null);
  const [imgWidth, setImgWidth] = useState(null);
  const [val, setVal] = useState({
    socialmedia_name: "",
    socialmedia_link: "",
  });
  useEffect(() => {
    const existingCard = JSON.parse(localStorage.getItem("selectedCard"));
    if (existingCard) {
      setSelectedCard(existingCard);
    } else {
      setSelectedCard(card);
    }
  }, []);
 
useEffect(()=>{
  const socialDetails = JSON.parse(localStorage.getItem("socialDetails"));
  console.log(socialDetails);
  if(socialDetails){
    setVal(socialDetails);
  }
},[]);
  useEffect(() => {
    const newCanvas = new fabric.Canvas("c", {
      backgroundColor: backgroundColor,
      selection: true,
    });
    fabric.Object.prototype.transparentCorners = false;
    newCanvas.preserveObjectStacking = true;

    setCanvas(newCanvas);
    newCanvas.setWidth(canvasWidth);
    newCanvas.setHeight(canvasHeight);

    const container = containerRef.current;
    if (container) {
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;
      const leftOffset = (containerWidth - canvasWidth) / 2;
      const topOffset = (containerHeight - canvasHeight) / 2;
      newCanvas.set({ left: leftOffset, top: topOffset });
    } else {
      console.error("Container reference is null or undefined.");
    }

    const gap = 30;
    for (let i = gap; i < canvasWidth - gap; i += 6) {
      const topLine = new fabric.Line([i, gap, i + 2, gap], {
        stroke: "white",
      });
      const bottomLine = new fabric.Line(
        [i, canvasHeight - gap, i + 2, canvasHeight - gap],
        { stroke: "white" }
      );
      newCanvas.add(topLine, bottomLine);
    }

    for (let i = gap; i < canvasHeight - gap; i += 6) {
      const leftLine = new fabric.Line([gap, i, gap, i + 2], {
        stroke: "white",
      });
      const rightLine = new fabric.Line(
        [canvasWidth - gap, i, canvasWidth - gap, i + 2],
        { stroke: "white" }
      );
      newCanvas.add(leftLine, rightLine);
    }

    const savedObject = JSON.parse(localStorage.getItem("textbox"));
    const defaultTextObject = {
      text: text,
      left: 150,
      top: 50,
      width: 150,
      fontSize: 22,
      fill: color,
      visible: false,
    };

    if (flag === "front") {
      const newTextObject = new fabric.Textbox(
        savedObject ? savedObject.text : defaultTextObject.text,
        {
          left: savedObject ? savedObject.left : defaultTextObject.left,
          top: savedObject ? savedObject.top : defaultTextObject.top,
          width: savedObject ? savedObject.width : defaultTextObject.width,
          fontSize: savedObject
            ? savedObject.fontSize
            : defaultTextObject.fontSize,
          fill: savedObject ? savedObject.fill : defaultTextObject.fill,
          visible: savedObject
            ? savedObject.visible
            : defaultTextObject.visible,
        }
      );

      newCanvas.add(newTextObject);
      newCanvas.moveTo(newTextObject, 10);
      setTextObject(newTextObject);
    }

    if (flag === "front" && image) {
      if (typeof image === "string") {
        const imgUrl = image;
        fabric.Image.fromURL(imgUrl, function(img) {
          img.scaleToWidth(imgWidth ? imgWidth : 400).set({
            left: 100,
            top: 60,
            angle: 0,
            selectable: true,
            visible: true,
          });
          newCanvas.add(img);
          setImageObject(img);
        });
      } else if (image instanceof File) {
        const reader = new FileReader();
        reader.onload = function(event) {
          const dataUrl = event.target.result;
          fabric.Image.fromURL(dataUrl, function(img) {
            img.scaleToWidth(imgWidth ? imgWidth : 400).set({
              left: 100,
              top: 50,
              angle: 0,
              selectable: true,
              visible: true,
            });
            newCanvas.add(img);
            setImageObject(img);
          });
        };
        reader.readAsDataURL(image);
      }
    }

    return () => {
      if (newCanvas) {
        newCanvas.dispose();
      }
    };
  }, [backgroundColor, canvasWidth, canvasHeight, image, flag, card]);

  useEffect(() => {
    const savedImageObject = JSON.parse(localStorage.getItem("imageObject"));
    if (savedImageObject && canvas && flag === "front") {
      fabric.Image.fromURL(savedImageObject.src, function(img) {
        img.set({
          left: savedImageObject.left,
          top: savedImageObject.top,
          angle: savedImageObject.angle,
          scaleX: savedImageObject.scaleX,
          scaleY: savedImageObject.scaleY,
          selectable: true,
          visible: savedImageObject.visible,
        });
        canvas.add(img);
        setImageObject(img);
      });
    }
  }, [canvas, flag]);
console.log(val);
  useEffect(() => {
    if (canvas && textObject) {
      const handleTextChange = () => {
        const newText = textObject.text;
        setText(newText);
        try {
          localStorage.setItem("textbox", JSON.stringify(textObject));
          console.log("Text saved to localStorage:", newText);
        } catch (error) {
          console.error("Error saving text to localStorage:", error);
        }
      };

      const handleTextModification = () => {
        try {
          localStorage.setItem("textbox", JSON.stringify(textObject));
          console.log("Text object position/size saved to localStorage");
        } catch (error) {
          console.error(
            "Error saving text object position/size to localStorage:",
            error
          );
        }
      };

      textObject.on("changed", handleTextChange);
      textObject.on("moving", handleTextModification);
      textObject.on("scaling", handleTextModification);

      return () => {
        textObject.off("changed", handleTextChange);
        textObject.off("moving", handleTextModification);
        textObject.off("scaling", handleTextModification);
      };
    }
  }, [textObject, canvas]);

  useEffect(() => {
    if (canvas && imageObject) {
      const handleImageModification = () => {
        try {
          localStorage.setItem("imageObject", JSON.stringify(imageObject));
          console.log("Image object saved to localStorage");
        } catch (error) {
          console.error("Error saving image object to localStorage:", error);
        }
      };

      imageObject.on("modified", handleImageModification);

      return () => {
        imageObject.off("modified", handleImageModification);
      };
    }
  }, [imageObject, canvas]);

  useEffect(() => {
    if (selectedCard) {
      localStorage.setItem("selectedCard", JSON.stringify(selectedCard));
    }
  }, [selectedCard]);

  const handleClickOutside = (event) => {
    if (
      colorPickerRef.current &&
      !colorPickerRef.current.contains(event.target)
    ) {
      setShowColorPicker(false);
    }
  };

  useEffect(() => {
    if (showColorPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showColorPicker]);

  const handleColorChange = (updatedColor) => {
    setColor(updatedColor.hex);
    if (textObject) {
      textObject.set("fill", updatedColor.hex);
      canvas.renderAll();
      localStorage.setItem("textbox", JSON.stringify(textObject));
    }
  };

  const addOrUpdateTextbox = () => {
    if (textObject) {
      if (textObject.visible) {
        canvas.remove(textObject);
        textObject.visible = false;
      } else {
        canvas.add(textObject);
        textObject.visible = true;
      }
      canvas.requestRenderAll();
      localStorage.setItem("textbox", JSON.stringify(textObject));
    } else {
      const newTextObject = new fabric.Textbox(text, {
        left: 300,
        top: 50,
        width: 150,
        fontSize: 20,
        fill: color,
      });
      canvas.add(newTextObject);
      setTextObject(newTextObject);
    }
  };

  const addOrUpdateImage = () => {
    if (imageObject) {
      if (imageObject.visible) {
        imageObject.set({ visible: false });
        canvas.requestRenderAll();
      } else {
        imageObject.set({ visible: true });
        canvas.requestRenderAll();
      }
      localStorage.setItem("imageObject", JSON.stringify(imageObject));
    } else if (typeof image === "string") {
      const imgUrl = image;
      fabric.Image.fromURL(imgUrl, function(img) {
        img.scaleToWidth(imgWidth ? imgWidth : 400).set({
          left: 100,
          top: 60,
          angle: 0,
          selectable: true,
          visible: true,
        });
        canvas.add(img);
        setImageObject(img);
      });
    } else if (image instanceof File) {
      const reader = new FileReader();
      reader.onload = function(event) {
        const dataUrl = event.target.result;
        fabric.Image.fromURL(dataUrl, function(img) {
          img.scaleToWidth(imgWidth ? imgWidth : 400).set({
            left: 100,
            top: 50,
            angle: 0,
            selectable: true,
            visible: true,
          });
          canvas.add(img);
          setImageObject(img);
        });
      };
      reader.readAsDataURL(image);
    }
  };

  const deleteImage = () => {
    if (imageObject) {
      canvas.remove(imageObject);
      setImageObject(null);
      setImage(null);
      localStorage.removeItem("imageObject");
      canvas.requestRenderAll();
    }
  };

  const deleteText = () => {
    try {
      if (!canvas) {
        console.error("Canvas is not defined");
        return;
      }

      if (textObject) {
        console.log("Removing text object from canvas");
        canvas.remove(textObject);
        setTextObject(null);
        setText("Type Here");
        localStorage.removeItem("textbox");
        canvas.requestRenderAll();
      } else {
        console.log("No text object to remove");
      }
    } catch (error) {
      console.error("Error deleting text object:", error);
    }
  };

  const handleChange1 = (e) => {
    // e.prventDefault();
    const { name, value } = e.target;
    setVal((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
  //  localStorage.setItem("socialDetails",JSON.stringify(val))
  };
  
  const handlePlacementChange = async (e) => {
    const placement = e.target.value;
    // console.log(placement);
    // console.log(selectedCard);

    const updatedCard = { ...selectedCard, nfc_icon_placement: placement };

    setSelectedCard(updatedCard);
    localStorage.setItem("selectedCard", JSON.stringify(updatedCard));

    try {
      const id = selectedCard.id; // Assuming selectedCard contains an id property
      const response = await axios.put("api/getcards/changeplacement", {
        id,
        newPlacement: placement, // Match the expected property name in the backend
      });
      console.log(`Success: ${response.data.message}`);
    } catch (error) {
      if (error.response) {
        console.log(`Error: ${error.response.data.error}`);
      } else {
        console.log("Error: Something went wrong");
      }
    }
  };
  console.log(val);
  return (
    <>
      <div className="col-md-2">
        {!image && (
          <input
            type="file"
            ref={inputRef}
            style={{ display: "none" }}
            onChange={(e) => {
              const file = e.target.files[0];
              setImage(file);
            }}
          />
        )}
        <ul className="navbar-nav justify-content-center p-2 mt-5">
          <li className="p-3">
            <FontAwesomeIcon
              size="2x"
              onClick={addOrUpdateTextbox}
              icon={faKeyboard}
            />
            <h6> ADD TEXT</h6>
          </li>
          <li className="p-3">
            <label htmlFor="file-upload" className="text-light">
              <FontAwesomeIcon
                size="2x"
                icon={faImage}
                onClick={addOrUpdateImage}
              />
              {!image && (
                <input
                  id="file-upload"
                  type="file"
                  style={{ display: "none" }}
                  ref={inputRef}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setImage(file);
                  }}
                />
              )}
            </label>
            <h6>ADD IMAGE </h6>
          </li>
          <li className="p-3">
            <FontAwesomeIcon
              size="2x"
              icon={faFillDrip}
              onClick={() => setShowColorPicker(!showColorPicker)}
            />
            <h6>CHANGE TEXT COLOR </h6>
          </li>
        </ul>
        {showColorPicker && (
          <div ref={colorPickerRef} onMouseDown={(e) => e.stopPropagation()}>
            <TwitterPicker
              color={color}
              className="color-selector position-absolute"
              onChange={handleColorChange}
            />
          </div>
        )}
      </div>
      <div className="col-md-8" style={{ position: "relative" }}>
        <Form>
          <Form.Group as={Row} className="mt-4" controlId="formPlaintextEmail">
            <Col md="4">
              <Form.Select
                className="from-control-plaintext"
                type="text"
                style={{ color: "white" }}
                value={val.socialmedia_name}
                name="socialmedia_name"
                onChange={(e) => {
                  handleChange1(e);
                }}
              >
                <option>Select Social Channel </option>
                <option className="text-white" value="facebook">
                  facebook
                </option>
                <option value="instagram">instagram</option>
                <option value="youtube">youtube</option>
                <option value="linkedin">linkedin</option>
                <option value="twitter">twitter</option>
                <option value="google">google</option>
              </Form.Select>
            </Col>

            <div className="col-md-4 col-11">
              <Form.Control
                name="socialmedia_link"
                value={val.socialmedia_link}
                onChange={(e) => {
                  handleChange1(e);
                }}
                placeholder="insert link"
              />
            </div>
          </Form.Group>
        </Form>
        <div
          ref={containerRef}
          className="canvas-container d-flex align-content-center justify-content-center mt-5"
        >
          <canvas id="c"></canvas>

          {flag === "front" && card && (
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                top: getSdTopPosition(selectedCard.nfc_icon_placement),
                right:
                  ["1", "3"].includes(selectedCard.nfc_icon_placement) &&
                  getSdLeftPosition(selectedCard.nfc_icon_placement),
                left:
                  ["2", "4"].includes(selectedCard.nfc_icon_placement) &&
                  getSdLeftPosition(selectedCard.nfc_icon_placement),
                display: "inline-flex",
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
            >
              <div style={{ position: "relative", minWidth: 0, flex: 1 }}>
                {/* <input
                  className="form-control-plaintext"
                  value={selectedCard && selectedCard.placeholder_text}
                  name="placeholder_text"
                  onChange={(e) => {
                    setSelectedCard({
                      ...selectedCard,
                      placeholder_text: e.target.value,
                    });
                  }}
                  dir="rtl"
                  ref={inputRef}
                /> */}
                <div className="nfc-icon-placement">
                  <img src={images.nfcsymbol} style={{ width: "33px" }} />
                </div>
              </div>
            </div>
          )}
        </div>
       <SelectedSocialPlatform platform={val} flag={flag} />

        <GenerateQRCode val={val} flag={flag} />
        <div className=" row mt-3">
          <div className="col-md-4" style={{ marginLeft: "95px" }}>
            <div className="form-item mb-3 ms-3 d-flex justify-content-start ">
              <select
                id="select"
                className="dropdown"
                name="nfcIconPlacement"
                value={selectedCard.nfc_icon_placement}
                onChange={handlePlacementChange}
                autoComplete="off"
                required
              >
                <option value="1">Top Right</option>
                <option value="2">Top Left</option>
                <option value="3">Bottom Right</option>
                <option value="4">Bottom Left</option>
              </select>
              <label htmlFor="select">NFC icon placement</label>
            </div>
          </div>
          <div className=" col-md-5 d-flex justify-content-end  mt-3">
            <Link className="linkyellow me-3 " onClick={deleteText}>
              Delete Text
            </Link>
            <Link className="linkyellow" onClick={deleteImage}>
              Delete Image
            </Link>
          </div>
        </div>
        <div className="vertical-rule">
          <p>5.88 CM</p>
        </div>
        <div className="horizontal-rule">
          <p>9.02 CM</p>
        </div>
      </div>
    </>
  );
}

export default SocialDetailCardEditor;
