import React from "react";
import { Link } from "react-router-dom";

function AdminSidebar() {
  return (
    <>
      <div className="col-md-2">
        <ul className="nav flex-column">
          <li>
            <Link to="/admin/dashboard">Dashboard</Link>
          </li>

          <li>
            <a
             
              data-bs-toggle="collapse"
              href="#collapseExample"
             
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Products
            </a>
            <div className="collapse" id="collapseExample">
              <div className="card-body">
                <ul>
                <li>
                  <Link to="/admin/cardlist">Cards</Link>
                </li>
                <li>
                  <Link to="/admin/taglist">Tags</Link>
                </li>
                <li>
                  <Link to="/admin/bandlist">Bands</Link>
                </li>
                <li>
                  <Link to="/admin/ringlist">Rings</Link>
                </li>
                </ul>
              </div>
            </div>
          </li>

          <li>
            <Link to="#">Customers</Link>
          </li>
          <li>
            <Link to="/admin/orders">Orders</Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default AdminSidebar;
