import React, { useState , useEffect} from "react";
import ShopHeader from "../layout/ShopHeader";
import Footer from "../layout/Footer";
import CardEditLayout from "../layout/CardEditLayout";
import RightSideBar from "../layout/RightSideBar";
import { useParams } from "react-router-dom";
import axios from "axios";
// import { Link } from "react-router-dom";
// import images from "../../utils/imageImports";
import FabricEditor from "../ExampleCard";
import MyCanvas from "./Fabric1";
import FabricEditor2 from "../Example2";
import images from "../../utils/imageImports";
import TagEditor from "./tags/TagEditor";
import BandEditor from "./bands/BandEditor";
import RingEditor from "./rings/RingEditor";
import SocialDetailCardEditor from "./SocialDetailCard/SocialDetailCardEditor"
function CardFrontPageEdit() {
  const { color, id } = useParams();
  const [card, setCard] = useState("");
  const [cardSide, setCardSide] = useState("front");

  const background = decodeURIComponent(color);

  useState(() => {
    const fetchCard = async () => {
      try {
        const response = await axios.get(`api/getcards/${id}`);
        setCard(response.data);
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCard();
  }, []);
  useEffect(()=>{
    if(card){
      localStorage.setItem("selectedCard", JSON.stringify(card))
    }
  },[card])
useEffect(()=>{
  if(background){
    localStorage.setItem("backgroundColor", JSON.stringify(background))
  }
},[background])
  const handleCardSide = (side) => {
    console.log(`Card side clicked: ${side}`);
    setCardSide(side);
  };
console.log(cardSide);
const cardComponents = {
  1: MyCanvas,
  2: FabricEditor,
  3: TagEditor,
  4: BandEditor,
  5: RingEditor ,
  6: SocialDetailCardEditor
};

const CardComponent = card && cardComponents[card.card_type] ? cardComponents[card.card_type] : null;

  return (
    <>
      <ShopHeader />
      <section className="dashboard">
        <div className="container mb-5">
          <div className="row my-5 g-0">
            <CardEditLayout card={card} background={background} />

            {CardComponent ? (
              <>
                {cardSide === "front" && (
                  <CardComponent card={card} background={background} flag="front" />
                )}
                {cardSide === "back" && (
                  <CardComponent background="#000000" card={card} flag="back" />
                )}
              </>
            ) : (
            
                <div
                  className="loading-container"
                  role="status"
                >
                  <img src={images.vyldfyreLogoAnimation} alt="loading" />
          
                </div>
              
            )}
          { card.card_type !== "4" && card.card_type !== "5" && <RightSideBar
              handleCardSide={handleCardSide}
              // cardSide={cardSide} // Pass cardSide to RightSideBar
              card={card}
            />}
            </div>
            </div>
          
      </section>
     
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Footer />
    </>
  );
}

export default CardFrontPageEdit;
