import React from "react";
import images from "../../utils/imageImports";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faUser } from "@fortawesome/free-solid-svg-icons";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

function ShopHeader({isAuthenticated, auth:{user}, cartTotalItems}) {
  return (
    <div>
  <div>
    <div className="container mb-5">
      <div className="row align-items-center">
        <div className="col-md-5 pt-3 my-4 my-md-0">
          <Link to="/vyldfyre-card">
            <img src={images.logo} className="img-fluid" alt="" />
          </Link>
        </div>
        <div id="dashbrdetails" className="col-md-7 pt-3 ">
        <ul className="nav flex-row justify-content-end">
  <li className="nav-item">
    <Link className="nav-link">
      <FontAwesomeIcon className="me-3" icon={faCircleQuestion} size="1.5x"/>
      Call for Help<br/><a className="yellowtext"> +91-40001010010</a>
    </Link>
  </li>
  <li className="nav-item">
    <Link className="nav-link" to="/profile">
      <FontAwesomeIcon icon={faUser} size="1x" className="me-3"/>
      { isAuthenticated ? "My Account" : "Login"}
    </Link>
  </li>
  <li className="nav-item">
    <Link to="#" className="nav-link">
      <FontAwesomeIcon icon={faCartShopping} className="me-3"  />
      <span className="badge start-100 translate-middle badge-rounded text-bg-light text-dark mb-3 mt-0">
        {cartTotalItems ? cartTotalItems : 0}
      </span>
      Cart
    </Link>
  </li>
</ul>

        </div>
      </div>
    </div>
  </div>
</div>

  );
}
ShopHeader.propTypes = {
  // login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  // googlelogin: PropTypes.func.isRequired,
  // setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
});
export default connect(mapStateToProps)(ShopHeader);
