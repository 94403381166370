import React, { useState, useEffect } from "react";
import ShopHeader from "../layout/ShopHeader";
import Footer from "../layout/Footer";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import images from "../../utils/imageImports";

function OrderConfirmPage({ orders: { orderDetails } }) {
  const [order, setOrder] = useState(null);
  const [orderedItems, setOrderedItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const currentDate = new Date().toLocaleDateString();

  useEffect(() => {
    const fetchOrderDetails = async (id) => {
      try {
        const res = await axios.get(`/api/cart/checkout/order/${id}`);
        console.log("Fetched order details:", res.data.data.result[0]);
        setOrder(res.data.data.result[0]);
      } catch (error) {
        console.error("Failed to fetch order details:", error);
      }
    };

    if (orderDetails) {
      fetchOrderDetails(orderDetails.insertId);
    }
  }, [orderDetails]);

  useEffect(() => {
    if (order) {
      const items = JSON.parse(order.ordered_items || "[]");
      setOrderedItems(items);
      const total = items.reduce(
        (total, item) => total + (item.itemTotal || 0),
        0
      );
      setTotalPrice(total);
    }
  }, [order]);

  if (!order) {
    return (
      <div
        className="loading-container"
        role="status"
      >
        <img src={images.vyldfyreLogoAnimation} alt="loading" />

      </div>
    );
  }

  console.log("Order:", order);
  console.log("Ordered Items:", orderedItems);

  return (
    <>
      <ShopHeader />

      <section id="thankyouconfirmation">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h5 className="fylowtxt">Confirmation</h5>
              <h1 className="my-5">
                Thank you for your
                <br /> purchase!
              </h1>
              <p className="mb-5">
                Your order will be processed within 24 hours during working
                hours. We
                <br /> will notify you by email once your order has been shipped.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <h2 className="mb-4">Billing address</h2>
                <div className="col-md-3 col-6 mb-4">Name</div>
                <div className="col-md-9 col-6 mb-4">
                  {order.full_name}
                </div>
                <div className="col-md-3 col-6 mb-4">Address</div>
                <div className="col-md-9 col-6 mb-4">
                  {order.address_line1}, {order.address_line2}
                  <br />
                  {order.town}, {order.state}, {order.zipcode}, {order.country}
                </div>
                <div className="col-md-3 col-6 mb-4">Phone</div>
                <div className="col-md-9 col-6 mb-4">{order.phoneNumber}</div>
                <div className="col-md-3 col-6 mb-4">Email</div>
                <div className="col-md-9 col-6 mb-4">{order.email_id}</div>
              </div>
            </div>

            <div id="tablesec" className="col-md-6">
              <table className="table">
                <thead className="table-dark">
                  <tr>
                    <th scope="col" className="ps-3">
                      Date
                      <br />
                      {currentDate}
                    </th>
                    <th scope="col">
                      Order Number
                      <br />
                      {order.id}
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="fw-bolder ps-3">Product name</td>
                    <td>Card Name</td>
                    <td>Price</td>
                  </tr>
                  {orderedItems.map((item, id) => (
                    <tr key={id}>
                      <td className="fw-bolder">
                        <img
                          className="cartimage"
                          src={item.cardFrontImage}
                          alt=""
                        />
                      </td>
                      <td>{item.card_name}</td>
                      <td>{item.card_price}</td>
                    </tr>
                  ))}
                  <tr>
                    <td className="fw-bolder"></td>
                    <td>Order Total</td>
                    <td>{totalPrice}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

OrderConfirmPage.propTypes = {
  orders: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  orders: state.orders,
});

export default connect(mapStateToProps)(OrderConfirmPage);
