import axios from "axios";
import { ADMIN_REGISTER_FAIL, ADMIN_REGISTER_SUCCESS, ADMIN_LOGIN_SUCCESS, ADMIN_LOGIN_FAIL,ADMIN_USER_LOADED,ADMIN_AUTH_ERROR , AXIOS_BASE_URL} from "./types";
import { setAlert } from './setAlert';
import setAuthToken from "../utils/setAuthToken";

axios.defaults.baseURL=AXIOS_BASE_URL

           //login //

export const adminlogin = (obj) => async dispatch => {
     const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = obj;
    try {
        const res = await axios.post('api/admin/users/login', body, config)
       console.log(res.data);
        dispatch({
            type: ADMIN_LOGIN_SUCCESS,
            payload: res.data
        });
dispatch(setAlert(res.data.message,'success'))
      //  console.log(res.data?.token)
    } catch (err) {
        console.log(err.response.data.error)
      //setAlert(errors,'danger');

        dispatch(setAlert(err.response.data.error,'danger'))
        dispatch({
            type: ADMIN_LOGIN_FAIL
        });

    }
}

// on userloaded //
export const adminloaduser = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    if (localStorage.admin_token) {
        console.log(localStorage.admin_token);
        setAuthToken(localStorage.admin_token);

    }
    try {
        const res = await axios.get('api/admin/users/login' , config);

       console.log(res);
        dispatch({
            type: ADMIN_USER_LOADED,
            payload: res.data
        })
    } catch (err) {
        console.log(err);
        console.log(err.response.data.error)
       // dispatch(setAlert(err.response.data.error, 'danger '))
        dispatch({
            type: ADMIN_AUTH_ERROR

        })
    }
}

         //register //


export const adminregister = (obj) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = obj;
    try {
        const res = await axios.post('api/admin/users/register', body, config)
        console.log(res);
        dispatch(setAlert(res.data, 'success'))
        dispatch({
            type: ADMIN_REGISTER_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        console.log(err)
        dispatch(setAlert(err.response.data, 'danger'))
        dispatch({
            type: ADMIN_REGISTER_FAIL
        });

    }
}


