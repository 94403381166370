import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AXIOS_BASE_URL } from "../../actions/types";
import images from "../../utils/imageImports";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createProfile } from "../../actions/profile";
import { updateProfile } from "../../actions/profile";
import {
  BasicDetailsContext,
  GalleryContext,
  SocialDetailsContext,
} from "../../utils/providers/RootProvider";
import { getSocialDetails, getGallery } from "../../actions/profile";
import getContrastTextColor from "../../utils/getTextColor";
// import { text } from "@fortawesome/fontawesome-svg-core";
function MobileView({
  auth: { user },
  profile: { profile },
  socialDetails: { socialDetails },
  gallery: { gallery },
  getSocialDetails,
  getGallery,
}) {
  const { data: basicDetailsData } = useContext(BasicDetailsContext);
  const { data: galleryData } = useContext(GalleryContext);
  const { data: socialDetailsData } = useContext(SocialDetailsContext);
  const [textColor, setTextColor] = useState("");
  const [details, setDetails] = useState({
    name: user.name,
    cover: user.cover_pic,
    profile: user.profile_pic,
    designation: user.designation,
    companyName: user.company_name,
    mobileNumber: user.phn_number,
    email: user.email,
    title: user.about_title,
    paragraph: user.about_paragraph,
    background: user.bg_theme ? user.bg_theme : "#102A68",
  });
  const [gallerys, setGallerys] = useState([]);
  const [socialDetail, setSocialDetail] = useState([]);

  useEffect(() => {
    if (basicDetailsData.name) {
      setDetails({
        name: basicDetailsData && basicDetailsData.name,
        cover: basicDetailsData && basicDetailsData.cover,
        profile: basicDetailsData && basicDetailsData.profile,
        designation: basicDetailsData && basicDetailsData.designation,
        companyName: basicDetailsData && basicDetailsData.companyName,
        mobileNumber: basicDetailsData && basicDetailsData.mobileNumber,
        email: basicDetailsData && basicDetailsData.email,
        title: basicDetailsData && basicDetailsData.title,
        paragraph: basicDetailsData && basicDetailsData.paragraph,
        background: basicDetailsData && basicDetailsData.background,
      });
    }
  }, [basicDetailsData]);

  useEffect(() => {
    getSocialDetails();
  }, [getSocialDetails]);

  useEffect(() => {
    getGallery();
  }, [getGallery]);

  useEffect(() => {
    setSocialDetail(socialDetails);
    if (socialDetailsData.length > 0) {
      setSocialDetail(socialDetailsData);
    }
  }, [socialDetailsData, socialDetails]);

  useEffect(() => {
    setGallerys(gallery);
    if (galleryData.length > 0) {
      setGallerys(galleryData);
    }
  }, [galleryData, gallery]);

  const renderImages = (source) => {
    return source.map((photo, index) => {
      // //console.log(photo)
      return (
        <div key={index} className=" mb-2">
          {photo.id && photo.image_name ? (
            <>
              <img
                src={`${AXIOS_BASE_URL}uploads/${photo.image_name}`}
                className="imagebox"
                alt="gallery"
              />
            </>
          ) : (
            <>
              <img
                src={URL.createObjectURL(photo)}
                className="imagebox"
                alt="gallery"
              />
            </>
          )}
        </div>
      );
    });
  };

  function checkType(variable) {
    if (typeof variable === "string" && variable.match(/^#[0-9A-Fa-f]{3,6}$/)) {
      return "This is a color";
    } else if (typeof variable === "string" && variable.includes("linear")) {
      return "this is a gradient";
    } else {
      //console.log("Variable is neither a File nor a color.");
    }
  }
  useEffect(() => {
    // Calculate text color only when details.background changes
    const calculateTextColor = () => {
      if (checkType(details.background) === "This is a color") {
        const text = getContrastTextColor(details.background);
        setTextColor(text);
      }
    };

    calculateTextColor();
  }, [details.background]);

  let backgroundStyle = {};
  if (checkType(details.background) === "This is a color") {
    backgroundStyle = { backgroundColor: details.background };
    // const text = getContrastTextColor(details.background)
    // setTextColor(text);
  } else if (checkType(details.background) === "this is a gradient") {
    backgroundStyle = { background: details.background };
  } else {
    if (
      typeof details.background === "string" &&
      details.background.startsWith("image")
    ) {
      // Assuming details.background is the image name
      backgroundStyle = {
        backgroundImage: `url(${AXIOS_BASE_URL}uploads/${details.background})`,
      };
    } else {
      // Assuming details.background is a File object
      try {
        backgroundStyle = {
          backgroundImage: `url(${details.background})`,
        };
      } catch (error) {
        console.error("Error creating Blob URL:", error);
      }
    }
  }
  // const textColor = getContrastTextColor(backgroundStyle)
  //  console.log(textColor);
  const getParagraphs = () => {
    return details.paragraph.split("\n"); // Split by newline characters
  };
  const sortedVal = socialDetail.slice().sort((a, b) => a.card_index - b.card_index);

  return (
    <>
      <div
        className="mobilecoverpage"
        style={{ ...backgroundStyle, color: textColor }}
      >
        <div className="inmob">
          <div className="coverpage d-flex flex-column align-items-center justify-content-center">
            {details.cover ? (
              typeof details.cover === "string" &&
              details.cover.startsWith("blob:") ? (
                <img
                  src={details.cover}
                  alt="coverpage"
                  className="coverImage"
                />
              ) : (
                <img
                  src={`${AXIOS_BASE_URL}uploads/${details.cover}`}
                  alt="coverpage"
                  className="coverImage"
                />
              )
            ) : (
              details.cover && (
                <img src={details.cover} className="coverImage" alt="cover" />
              )
            )}
          </div>
          <div className="contpage position-relative text-center">
            <div className="roundcam rounded-image-container ">
              {details.profile ? (
                typeof details.profile === "string" &&
                details.profile.startsWith("blob:") ? (
                  <img
                    src={details.profile}
                    alt="profilepic"
                    className="rounded-image"
                  />
                ) : (
                  <img
                    src={`${AXIOS_BASE_URL}uploads/${details.profile}`}
                    alt="profilepic"
                    className="rounded-image"
                  />
                )
              ) : (
                <div>{details.name && details.name[0]}</div>
              )}
            </div>
            <div className=" surname text-center ">
              <h4>{details.name}</h4>
              <p>{details.designation}</p>
              <p>{details.companyName}</p>
              <p className=" f11">{details.mobileNumber}</p>
              <p className="pb-4">{details.email}</p>
            </div>
          </div>

          {sortedVal.map((item, i) => {
            //console.log(item, "item");
            if (item.socialmedia_name === "facebook") {
              return (
                <div
                  key={i}
                  className="social-media-card justify-content-center w-100 mt-2 mb-2 "
                >
                  <Link to={item.socialmedia_link}>
                    <div className="col social-media-item text-center">
                      <div className="row">
                        <div className="col-1">
                          <img
                            className="social-media-logo"
                            style={{ color: "primary" }}
                            src={images.facebooksq}
                            alt=""
                          />
                        </div>
                        <div className="col-10 text-center"> facebook</div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            } else if (item.socialmedia_name === "instagram") {
              return (
                <div
                  key={i}
                  className="social-media-card justify-content-center mt-2 mb-2 "
                >
                  <Link to={item.socialmedia_link}>
                    <div className="col social-media-item w-10">
                      <div className="row">
                        <div className="col-1">
                          <img
                            className="social-media-logo"
                            style={{ color: "primary" }}
                            src={images.instagram}
                            alt=""
                          />
                        </div>
                        <div className="col-10 text-center"> Instagram</div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            } else if (item.socialmedia_name === "linkedin") {
              return (
                <div
                  key={i}
                  className="social-media-card justify-content-center  w-100 mt-2 mb-2 "
                >
                  <Link to={item.socialmedia_link}>
                    <div className="col social-media-item">
                      <div className="row">
                        <div className="col-1">
                          <img
                            className="social-media-logo"
                            style={{ color: "primary" }}
                            src={images.linkedin}
                            alt=""
                          />
                        </div>
                        <div className="col-10 text-center"> linkedIn</div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            } else if (item.socialmedia_name === "youtube") {
              return (
                <div
                  key={i}
                  className="social-media-card justify-content-center  w-100 mt-2 mb-2 "
                >
                  <Link to={item.socialmedia_link}>
                    <div className="col social-media-item ">
                      <div className="row">
                        <div className="col-1">
                          <img
                            className="social-media-logo"
                            style={{ color: "primary" }}
                            src={images.youtube}
                            alt=""
                          />
                        </div>
                        <div className="col-10 text-center"> Youtube</div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            } else if (item.socialmedia_name === "twitter") {
              return (
                <div
                  key={i}
                  className="social-media-card justify-content-center  w-100 mt-2 mb-2 "
                >
                  <Link to={item.socialmedia_link}>
                    <div className="col social-media-item text-center">
                      <div className="row">
                        <div className="col-1">
                          <img
                            className="social-media-logo"
                            style={{ color: "primary" }}
                            src={images.twitterx}
                            alt=""
                          />
                        </div>
                        <div className="col-10 text-center"> Twitter</div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            } else {
              return (
                <div
                  key={0}
                  className="innrmediabox w-100 social-icons mt-2 mb-2"
                >
                  <Link>
                    <img src={images.socialicon} alt="" />
                  </Link>
                </div>
              );
            }
          })}

          <div
            className=" innrmediabox2 p-3 bio text-start mt-2 mb-2 f12"
            style={{ background: "white" }}
          >
            <h6 className="text-dark my-2">{details.title}</h6>
            {details.paragraph && getParagraphs().map((paragraph, index) => (
              <>
              <p key={index} style={{ fontSize: "14px", color: "black", marginBottom: "12px", display: "block" ,minHeight: "12px"}}>
                {paragraph}
                <span />
              </p>
              
              </>
            ))}
            
          </div>
          <p className="mt-3">Work Gallery</p>
          <div className=" mt-3 mb-5">{renderImages(gallerys)}</div>
          {/* <p className="text-center mt-5 ff14">VYLDFYRE</p> */}
        </div>
      </div>
    </>
  );
}

MobileView.propTypes = {
  createProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  // getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  profile: state.profile,
  socialDetails: state.socialDetails,
  gallery: state.gallery,
  // loading: state.auth.loading
});

export default connect(mapStateToProps, {
  createProfile,
  updateProfile,
  getSocialDetails,
  getGallery,
})(MobileView);
