import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import AdminHeader from "../../components/layout/AdminHeader";
import AdminSidebar from "../../components/layout/AdminSidebar";
import Footer from "../../components/layout/Footer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faAngleDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useImmer } from "use-immer";
import { BlockPicker } from "react-color";
import defaultColors from "../../utils/defaultColors";
import { AXIOS_BASE_URL } from "../../actions/types";
import { updateCard } from "../../actions/adminCards";
function UpdateCard({updateCard}) {
  const location = useLocation();
  const param = useParams();
const navigate = useNavigate();
    // console.log(location.state.item);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedInput, setSelectedInput] = useState(null);
  const [color, setColor] = useState("");
  const storeElement = location.state?.item || {} ;
  const [card, setCard] = useState("");

  useEffect( () =>{
    const fetchCard = async ()=>{
        try {
            const res = await axios.get (`${AXIOS_BASE_URL}api/admin/users/cards/${param.id}`)
            console.log(res.data,"hello");
            setCard(res.data);
        } catch (error) {
            console.log(error);
        }
    }
fetchCard();
    
  },[])
  const [val, setVal] = useState(["#000000"]);
  const [itemColors, setItemColors] = useState(
    Array.from({ length: val.length }, () => "")
  );
  const [palette, setPalette] = useImmer({
    collection: [...defaultColors],
    activeColorId: defaultColors.find((color) => color.active).id,
    currentColor: defaultColors.find((color) => color.active).default,
  });
  const presetColors = palette.collection.map((color) => color.default);
  console.log(card);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setCard({ ...card, [name]: value });
  };
  const handleColor = (id) => (updatedColor) => {
    console.log("hello");
    const updatedColors = [...card.colors];
    updatedColors[id] = updatedColor.hex; // Update the color at the specified index
    setCard((prevState) => ({
      ...prevState,
      colors: updatedColors,
    }));
    setColor(updatedColor.hex);
  };
  const handleChangeColor = (id) => {
    console.log("hello");
    console.log(id);
    setShowColorPicker(!showColorPicker);
    setSelectedInput(id);
  };

  const handleDelete = (id) => {
    // console.log("hello");
    const delval = [...card.colors];
    delval.splice(id, 1);
    setCard({ ...card, colors: delval });
  };

  const handleAdd = () => {
    console.log("hello");
    setCard((prevState) => ({
      ...prevState,
      colors: [...prevState.colors, ...val],
    }));
  };
  const handleSubmit = async (e) => {
    console.log(card);
    e.preventDefault();

    const formData = new FormData();
    formData.append("cardName", card.card_name);
    formData.append("cardPrice", card.card_price);
    formData.append("cardType", card.cardType)
    formData.append("nfcIconPlacement", card.nfc_icon_placement);
    formData.append("placeholderText", card.placeholder_text);
    formData.append("background_image", card.background_image);
    formData.append(
      "selectedColors",
      JSON.stringify(card.colors)
    );
    try {
        await updateCard(card.id, formData)
        navigate("/admin/cardlist")
    } catch (err) {
        console.log(err);
    //   console.log(err.response.data.error); 
    }

  };
  const handleImageUpload = (e) => {
    console.log(e);
    console.log(e.target.files);
    setCard({ ...card, [e.target.name]: e.target.files[0] });
  };

  return (
    <>
      <AdminHeader />
      <section className="dashboard">
        <div className="container mb-5">
          <div className="row my-5 g-0">
            <AdminSidebar />
            <div className="col-md-10">
              <h1>Update Card {card.card_name} </h1>
              <div className="row mt-5">
                <h6 className="pb-4">Basic details</h6>
                <div className="col-md-4">
                  <div className="form-item mb-3">
                    <input
                      type="text"
                      id="cardname"
                      name="card_name"
                      value={card.card_name}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                    />
                    <label htmlFor="cardname">Card name</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-item mb-3">
                    <input
                      type="text"
                      id="cardprice"
                      name="card_price"
                      value={card.card_price}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                    />
                    <label htmlFor="cardprice">Card price</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-item mb-3">
                  <select
                      id="select"
                      className="dropdown"
                      name="cardType"
                      value={card.cardType}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                    >
                      <option  disabled>Vyldfyre Themed NFC card</option>
                      <option value="1">Digital Business Plain card</option>
                      <option value="2"> NFC coin </option>
                      <option value="3">NFC Tag</option>
                      <option  disabled>Vyldfyre Themed NFC card</option>
                      <option value="4">NFC Band</option>
                      <option value="5">NFC Ring</option>
                      <option value="6">Digital Social Media Card</option>



                     
                    </select>
                    
                    <label htmlFor="select">NFC Card Type</label>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <h6 className="pb-4">Colour variants</h6>
                {card.colors && card.colors.map((col, id) => (
                  <div key={id} className="col-md-4">
                    <div className="form-item mb-3">
                      <input
                        type="text"
                        id={`cardname-${id}`}
                        value={col}
                        autoComplete="off"
                        // onChange={()=>handleChangeColor()}
                        onClick={() => handleChangeColor(id)}
                        required
                      />
                      <div className="colorboxpick" style={{ background: col }}>
                        <Link onClick={() => handleChangeColor(id)}>
                          <FontAwesomeIcon icon={faAngleDown} />{" "}
                        </Link>
                      </div>
                      <span className="delete-icon">
                        <FontAwesomeIcon
                          onClick={() => handleDelete(id)}
                          icon={faXmark}
                        />
                      </span>
                      {showColorPicker && selectedInput === id && (
                        <BlockPicker
                          color={itemColors[id]}
                          className="hover position-absolute zindex-100"
                          onChange={handleColor(id)}
                        />
                      )}
                    </div>
                  </div>
                ))}

                <div className="col-md-4">
                  <div className="form-item mb-3">
                    <Link onClick={handleAdd}> + add new color </Link>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <h6 className="pb-4">Background image</h6>
                <div className="col-md-4 d-flex flex-column mb-4">
                  <label htmlFor="background-image" className="uploadbtn">
                    <i>
                      <FontAwesomeIcon icon={faArrowUpFromBracket} />
                    </i>{" "}
                    Upload background image
                  </label>
                </div>
                <div className="col-md-5">
                  {card.background_image ? (
                    // If card.background_image exists (i.e., it's not null or undefined)
                    typeof card.background_image === "string" ? (
                      // If card.background_image is a string, it's likely a filename
                      <img
                        src={`${AXIOS_BASE_URL}uploads/${card.background_image}`}
                        className="img-fluid w-100"
                        alt=""
                      />
                    ) : (
                      // If card.background_image is not a string, it's likely a file object
                      <img
                        src={URL.createObjectURL(card.background_image)}
                        className="img-fluid w-100"
                        alt=""
                      />
                    )
                  ) : (
                    // If card.background_image is null or undefined (i.e., no image uploaded)
                    // You can display a placeholder image or any other content here
                    <span>No image uploaded</span>
                  )}
                </div>
                <input
                  type="file"
                  id="background-image"
                  name="background_image"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e)}
                  style={{ display: "none" }} // Hide the input element
                />
              </div>
              <div className="row mt-5">
                <h6 className="pb-4">NFC icon & Custom Field</h6>
                <div className="col-md-4">
                  <div className="form-item mb-3">
                    <select
                      id="select"
                      className="dropdown"
                      name="nfc_icon_placement"
                      value={card.nfc_icon_placement}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                    >
                      <option value="1">Top Right</option>
                      <option value="2">Top Left</option>
                      <option value="3">Bottom Right</option>
                      <option value="4">Bottom Left</option>
                    </select>
                    <label htmlFor="select">NFC icon placement</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-item mb-3">
                    <input
                      type="text"
                      id="cstfield"
                      name="placeholder_text"
                      value={card.placeholder_text}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                    />
                    <label htmlFor="cstfield">
                      Custom field placeholder text
                    </label>
                  </div>
                </div>
              </div>
              <div id="backcarddtls" className="row mt-5">
                <h6 className="pb-4">Back card details</h6>
                <div className="col-md-12 mb-4">
                  <div className="checkboxtxt">
                    <input id="checkbox1" type="checkbox" />
                    <label htmlFor="checkbox1">include back</label>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="checkboxtxt">
                    <input id="checkbox2" type="checkbox" />
                    <label htmlFor="checkbox2">Blank design</label>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="checkboxtxt">
                    <input id="checkbox3" type="checkbox" />
                    <label htmlFor="checkbox3">Duplicate front design</label>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="checkboxtxt">
                    <input id="checkbox4" type="checkbox" />
                    <label htmlFor="checkbox4">Upload own design</label>
                  </div>
                </div>
              </div>
              <div className="row my-5 mt-5">
                <div className="col-md-8"></div>
                <div className="col-md-4 mt-5 text-md-end">
                  <a href="#" className="cansbtbtns">
                    {" "}
                    Cancel{" "}
                  </a>{" "}
                  <Link onClick={handleSubmit} className="cansbtbtns ms-3">
                    {" "}
                    Save{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

UpdateCard.propTypes = {
    updateCard: PropTypes.func.isRequired,
};

export default connect(null, { updateCard })(UpdateCard);
