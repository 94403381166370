import React from "react";
import { Helmet } from "react-helmet";
import images from "./imageImports";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  EmailIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";
import { Link } from "react-router-dom";
import { BASE_URL } from "../actions/types";

function ShareSocial2({ publicUrl }) {
  const url = "https://accounts.vyldfyre.com/vyldfyre/profile/" + publicUrl;

  return (
    <div className="row mt-3">
      <Helmet>
        <meta property="og:url" content={url} />
        <meta property="og:title" content="VYLDFYRE" />
        <meta property="og:description" content="Your Page Description" />
        <meta property="og:image" content="./logonew.png" />
      </Helmet>
      <div className="social-buttons-container">
        <FacebookShareButton
          url={url}
          quote="Check out this awesome website!"
          appId="721783403402627"
          media={images.mobileLogo}
          className="social-button"
        >
          <Link className="social-link">
            <FacebookIcon size={30} />
          </Link>
        </FacebookShareButton>

        <LinkedinShareButton
          url={url}
          className="social-button"
        >
          <Link className="social-link">
            <LinkedinIcon size={30} />
          </Link>
        </LinkedinShareButton>

        <TwitterShareButton
          url={BASE_URL + "vyldfyre/profile/" + publicUrl}
          className="social-button"
        >
          <Link className="social-link">
            <TwitterIcon size={30} />
          </Link>
        </TwitterShareButton>

        <WhatsappShareButton
          url={BASE_URL + "vyldfyre/profile/" + publicUrl}
          className="social-button"
        >
          <Link className="social-link">
            <WhatsappIcon size={30} />
          </Link>
        </WhatsappShareButton>

        <FacebookMessengerShareButton
          url={BASE_URL + "vyldfyre/profile/" + publicUrl}
          className="social-button"
        >
          <Link className="social-link">
            <FacebookMessengerIcon size={30} />
          </Link>
        </FacebookMessengerShareButton>

        <EmailShareButton
          url={BASE_URL + "vyldfyre/profile/" + publicUrl}
          className="social-button"
        >
          <Link className="social-link">
            <EmailIcon size={30} />
          </Link>
        </EmailShareButton>
      </div>
    </div>
  );
}

export default ShareSocial2;
