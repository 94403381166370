import React, { useState, useEffect } from "react";
import AdminSidebar from "../../components/layout/AdminSidebar";
import AdminHeader from "../../components/layout/AdminHeader";
import Footer from "../../components/layout/Footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteCard, getAdminCards } from "../../actions/adminCards";
import { setAlert } from "../../actions/setAlert";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function CardsList({ getAdminCards, cards: { cards }, deleteCard, setAlert }) {
  const [cardData, setCardData] = useState([]);
  const [deleteCardId, setDeleteCardId] = useState("");

  useEffect(() => {
    getAdminCards();
  }, [getAdminCards]);

  useEffect(() => {
    if (cards && cards.length) {
      setCardData(cards);
    }
  }, [cards]);
console.log(cards);
  const storeDeleteId = (id) => {
    setDeleteCardId(id);
  };

  const handleDelete = async () => {
    try {
      await deleteCard(deleteCardId);
      getAdminCards();  // Fetch updated cards list after deletion
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <AdminHeader />
      <section className="dashboard">
        <div className="container mb-5">
          <div className="row my-5 g-0">
            <AdminSidebar />
            <div className="col-md-10">
              <h1>Cards</h1>
              <Link className="float-end btn probtn text-light" to="/admin/createcard" > Create Card </Link>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Card Name</th>
                    <th scope="col">Card Price</th>
                    <th scope="col">NFC Icon Placement</th>
                    <th scope="col">Placeholder Text</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {cardData &&
                    cardData.map((item, index) => (
                      <tr key={item.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.card_name}</td>
                        <td>{item.card_price}</td>
                        <td>{item.nfc_icon_placement}</td>
                        <td>{item.placeholder_text}</td>
                        <td>
                          <Link to={`/admin/updatecard/${item.id}`}>
                            Update Card
                          </Link>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-link p-0"
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModal"
                            onClick={() => storeDeleteId(item.id)}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                aria-labelledby="deleteModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header bg-dark text-white">
                      <h5 className="modal-title" id="deleteModalLabel">
                        Delete Confirmation
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body bg-dark text-white">
                      <p>Are you sure you want to delete this card?</p>
                    </div>
                    <div className="modal-footer bg-dark">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={handleDelete}
                        data-bs-dismiss="modal"
                      >
                        Delete Card
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

CardsList.propTypes = {
  getAdminCards: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  deleteCard: PropTypes.func.isRequired,
  cards: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  cards: state.cards,
});

export default connect(mapStateToProps, { setAlert, getAdminCards, deleteCard })(
  CardsList
);
