import React from "react";
import { Link } from "react-router-dom";
import images from "../../utils/imageImports";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import whastappfooter from "../../images/whatsappfooter.png"
import { faXTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="d-none d-sm-block">
            <div className="row line">
              <div className="col-md-10">
                <ul className="nav justify-content-start">
                  <li className="nav-item">
                    <Link className="nav-link">
                      <img src={images.footerLogo} alt="" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/vyldfyre-card">
                      Shop Vyldfyre NFC Card
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" href="#">
                      How it works
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://wa.me/918977462399" target="_blank" >
                      Contact us
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-2">
                <ul className="nav justify-content-end">
                <li className="nav-item">
                    <a className="nav-link" href="https://wa.me/918977462399" target="_blank" >
                      <i className="fa-brands fa-x-twitter">
                       <img src={whastappfooter} />
                      </i>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://www.instagram.com/vyldfyre/"  target="_blank" >
                      <i className="fa-brands fa-x-twitter">
                        <FontAwesomeIcon icon={faInstagram} />
                      </i>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link pe-0" href="https://twitter.com/vyldfyre"  target="_blank" >
                      <i className="fa-brands fa-x-twitter">
                        <FontAwesomeIcon icon={faXTwitter} />
                      </i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ul className="nav">
                  <li className="nav-item">
                    <Link className="nav-link" href="#">
                      Terms
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" href="#">
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" href="#">
                      Refund & Returns
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* <!-- For mobile resposive -->         */}
          <div className="d-md-none">
            <div className="row line">
              <div className="col-md-12 mb-5">
                <ul className="nav">
                  <li className="nav-item">
                    <Link className="nav-link" href="#">
                      <img src={images.footerLogo} alt="" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link linksicons" href="https://wa.me/918977462399" target="_blank" >
                      <img src={whastappfooter} />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link linksicons" href="https://www.instagram.com/vyldfyre/" target="_blank" >
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link pe-0" href="https://twitter.com/vyldfyre" target="_blank" >
                      <i className="fa-brands fa-x-twitter"></i>
                    </a>
                  </li>
                </ul>
                <ul className="nav">
                  <li className="nav-item">
                    <Link className="nav-link" to="/vyldfyre-card">
                      Shop Vyldfyre NFC Card
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" href="#">
                      How it works
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link"  href="https://wa.me/918977462399" target="_blank"  >
                      Contact us
                    </a>
       
                  </li>
                </ul>
                <ul className="nav">
                  <li className="nav-item">
                    <Link className="nav-link" href="#">
                      Terms
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" href="#">
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" href="#">
                      Refund & Returns
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
