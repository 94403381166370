import {
   PROFILE_ERROR,
    ORDERS,
 
  
  } from "../actions/types";
  const initialState = {
    
    orderDetails:{} ,
    errors:{},
  };

  export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ORDERS:
      return {
        ...state,
        orderDetails: payload,
        loading: false,
      };
    case PROFILE_ERROR:
      return {
        ...state,
        errors: payload,
        loading: false,
      };

    default:
      return state;
  }
  }