import React, { useState, useEffect } from "react";
import ShopHeader from "../layout/ShopHeader";
import Footer from "../layout/Footer";
//  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import images from "../../utils/imageImports";
import { Link } from "react-router-dom";
import { getCards } from "../../actions/cards";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { AXIOS_BASE_URL } from "../../actions/types";
function VyldCard({ getCards, cards: { cards } }) {

  const [totalCards, setTotalCards] = useState([]);
  // const [background, setBackground] = useState("");
  useEffect(() => {
    getCards();
  }, []);
  useEffect(() => {
    setTotalCards(cards.map((card) => ({ ...card, background: "#000000" })));
  }, [cards]);
  console.log(cards);
  const handleColor = (color, index) => {
    const updatedCards = [...totalCards]; // Create a copy of totalCards array
    updatedCards[index].background = color; // Update the background color
    setTotalCards(updatedCards); // Update the state variable
  };
  // console.log(totalCards);
  function getTopPosition(card_type) {
    switch (card_type) {
      case "1":
      
        return "10px"; // Adjust as needed
     
      case "2":
        return " 22px"; // Adjust as needed
      default:
        return "50%"; // Default to center if placement is not defined
    }
  }

  function getLeftPosition(card_type) {
    switch (card_type) {
      case "1":
      case "3":
        return "calc(100% - 286px)"; // Adjust as needed
      case "2":
      case "4":
        return "105px"; // Adjust as needed
      default:
        return "50%"; // Default to center if placement is not defined
    }
  }

  return (
    <div>
      <ShopHeader />
      <section id="user" className="dashboard">
        <div className="container mb-5">
          <div className="row my-5 ">
            <div className="col-md-12">
              <h1 className="mt-3 mb-3"> Shop Vyldfyre</h1>
              <p className="yellowtext">One from ₹750.00</p>
              <div className="row">
                {cards &&
                  totalCards &&
                  totalCards.map((card, index) => (
                    <>
                      <div
                        key={index}
                        className="col-md-3 pt-3 "
                      >
                        <div className="uploadcard mb-2 me-3">
                        <Link
                          to={`/vyldfyrefrontcard/${encodeURIComponent(
                            card.background
                          )}/${card.id}`}
                         
                          className="text-light"
                        >
                         

                          <div
                            className="d-flex justify-content-center p-2 "
                            style={{
                              position: "relative",
                              // background: card.background,
                            }}
                          >
                            <img
                              src={`${AXIOS_BASE_URL}uploads/${card.background_image}`}
                            />
                            <div
                            className="position-absolute"
                              style={{
                               
                                zIndex: 10,
                                top: getTopPosition(card.card_type),
                                right:
                                  ["1", "3"].includes(
                                    card.card_type
                                  ) && getLeftPosition(card.nfc_icon_placement), // Right for 1 & 3, otherwise auto
                                left:
                                  ["2", "4"].includes(
                                    card.card_type
                                  ) && getLeftPosition(card.card_type), // Left for 2 & 4, otherwise auto
                                display: "inline-flex",
                                alignItems: "center",
                                whiteSpace: "nowrap", // Prevent text from wrapping
                              }}
                            >
                              {(card.card_type === "1" ||
                                card.card_type === "2") && (
                                <>
                                 
                                  {/* Wrap text in a span */}
                                  <img
                                    src={images.nfcsymbol}
                                    style={{
                                      width: "20px",
                                      marginLeft: "5px",
                                      verticalAlign: "middle",
                                    }} // Adjust margin and vertical alignment
                                    alt="NFC Symbol"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                         
                        </Link>
                        {/* <div className="row ms-0">
                            {card.colors.map((color, id) => (
                              <div
                                key={id}
                                onClick={() => {
                                  handleColor(color, index);
                                }}
                                className="colorboxpick col-md-1"
                                style={{ background: color }}
                              ></div>
                            ))}
                          </div> */}
                      </div>
                      <span className="text-white">{card.card_name}</span>
                          <p className="mb-3" > one from ₹ {card.card_price}</p>
                          </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <br /> <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}
VyldCard.propTypes = {
  getCards: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  cards: state.cards,
});
export default connect(mapStateToProps, { getCards })(VyldCard);
