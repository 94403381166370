import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { loaduser } from "./actions/auth";
import { adminloaduser } from "./actions/AdminAuth";
import setAuthToken from "./utils/setAuthToken";

import Login from "./components/authentication/Login";
import SignUp from "./components/authentication/SignUp";
import HomePage from "./components/HomePage";
import Shop from "./components/shop/Shop";
import AdminLogin from "./admin/auth/AdminLogin";
import AdminSignUp from "./admin/auth/AdminSignUp";
import VyldCard from "./components/shop/VyldCard";
import CardFrontPageEdit from "./components/shop/CardFrontPageEdit";
import CardWithImage from "./components/shop/CardWithImage";
import ConfirmPage from "./components/shop/ConfirmPage";
import PublicProfile from "./components/public/PublicProfile";
import EmailValidate from "./utils/EmailValidate";
import Alert from "./components/layout/Alert";
import AuthenticatedRoutes from "./routes/authenticatedRoutes";
import VyldfyreTags from "./components/shop/tags/VyldfyreTags";
import VyldfyreBands from "./components/shop/bands/VyldfyreBands";
import VyldfyreRings from "./components/shop/rings/VyldfyreRings";
import InitialDataContextSetter from "./utils/contexts/InitialDataContextSetter";
import { CartProvider } from "react-use-cart";

import "./styles/login.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./styles/cards.css";
import "./styles/styles.css";
import "./styles/admin.css";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}
if (localStorage.admin_token) {
  setAuthToken(localStorage.admin_token);
}

function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isAdminAuthenticated = useSelector((state) => state.adminauth.adminIsAuthenticated);
  const [role, setRole] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(loaduser());
    } else if (localStorage.getItem("admin_token")) {
      dispatch(adminloaduser());
    }
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      setRole("user");
    } else if (isAdminAuthenticated) {
      setRole("admin");
    } else {
      setRole("");
    }
  }, [isAuthenticated, isAdminAuthenticated]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <InitialDataContextSetter>
      <CartProvider>
        <Router>
          <Alert />
          <Routes>
            <Route index path="/" element={<HomePage />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin/register" element={<AdminSignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/vyldfyre-card" element={<VyldCard />} />
            <Route path="/vyldfyre-tags" element={<VyldfyreTags />} />
            <Route path="/vyldfyre-bands" element={<VyldfyreBands />} />
            <Route path="/vyldfyre-rings" element={<VyldfyreRings />} />
            <Route path="/vyldfyrefrontcard/:color/:id" element={<CardFrontPageEdit />} />
            <Route path="/vyldfyreimagecard" element={<CardWithImage />} />
            <Route path="/confirmcard" element={<ConfirmPage />} />
            <Route path="/vyldfyre/profile/:username" element={<PublicProfile />} />
            <Route path="/users/:id/verify/:token" element={<EmailValidate />} />
            <Route path="/*" element={<AuthenticatedRoutes userRole={role} />} />
          </Routes>
        </Router>
      </CartProvider>
    </InitialDataContextSetter>
  );
}

export default App;
