import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/layout/AdminHeader";
import AdminSidebar from "../../components/layout/AdminSidebar";
import Footer from "../../components/layout/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import { setAlert } from "../../actions/setAlert";

function OrderDetails() {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [orderedItems, setOrderedItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [orderStatus, setOrderStatus] = useState(null);
  const currentDate = new Date().toLocaleDateString();

  useEffect(() => {
    const fetchOrderDetails = async (id) => {
      try {
        const res = await axios.get(`/api/cart/checkout/order/${id}`);
        console.log("Fetched order details:", res.data.data.result[0]);
        setOrder(res.data.data.result[0]);
        setOrderStatus(res.data.data.result[0].order_status); // Initialize order status
      } catch (error) {
        console.error("Failed to fetch order details:", error);
      }
    };

    if (id) {
      fetchOrderDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (order) {
      const items = JSON.parse(order.ordered_items || "[]");
      setOrderedItems(items);
      const total = items.reduce(
        (total, item) => total + (item.itemTotal || 0),
        0
      );
      setTotalPrice(total);
    }
  }, [order]);

  console.log(order);

  const handleChange = async (e) => {
    e.preventDefault();
    const status = e.target.value;
    setOrderStatus(status);
    setOrder({ ...order, order_status: status });
    try {
      const response = await axios.put('api/cart/checkout/order/update-order-status', {
        id,
        status,
      });
      setAlert(response.data.message, "success");
    } catch (error) {
      setAlert(error.response ? error.response.data.error : 'Error updating order status', "danger");
    }
  };

  return (
    <>
      <AdminHeader />
      <section className="dashboard">
        <div className="container mb-5">
          <div className="row my-5 g-0">
            <AdminSidebar />
            <div id="tablesecorder" className="col-md-10">
              <h1>Order Details</h1>

              <p className="mt-2">
                ordered on: {order && order.created_date} Order : #{id}
              </p>
              <div className="d-flex justify-content-end mb-3 mt-3">
                <div className="col-md-4">
                  <div className="form-item mb-3">
                    <select
                      id="select"
                      className="dropdown"
                      name="status"
                      value={orderStatus || ""}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                    >
                      <option value="processing">Processing</option>
                      <option value="shipped">Shipped</option>
                    </select>
                    <label htmlFor="select">Order Status</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 order-card mt-4 me-5 p-3">
                  <h6>Shipping Address</h6>
                  <p className="mt-2">
                    {order && order.address_line1},{" "}
                    {order && order.address_line2}, {order && order.town},{" "}
                    {order && order.state}, {order && order.zipcode} ,{" "}
                    {order && order.country}.
                  </p>
                </div>
                <div className="col-md-5 order-card mt-4 ms-5 p-3">
                  <h6>User Info & Contact Details </h6>

                  <p className="d-flex  ms-3">
                    <span className="me-5">Name:</span>
                    {order && order.first_name} {order && order.last_name}
                  </p>
                  <p className="d-flex  ms-3">
                    <span className="me-5"> Mobile Number:</span>
                    {order && order.phoneNumber}
                  </p>
                  <p className="d-flex  ms-3">
                    <span className="me-5"> Company name:</span>{" "}
                    {order && order.company_name}
                  </p>
                  <p className="d-flex  ms-3">
                    <span className="me-5"> Email Id:</span>{" "}
                    {order && order.email_id}{" "}
                  </p>
                </div>
                Order Summary:
                <div className="col-md-11 order-items mt-5 ">
                  <table className="table">
                    <tbody className="fw-bolder">
                      <tr>
                        <td className="  ps-3">Product name</td>
                        <td>Card Name</td>
                        <td>Price</td>
                      </tr>
                      {orderedItems.map((item, id) => (
                        <tr key={id}>
                          <td className="fw-bolder">
                            <img
                              className="cartimage"
                              src={item.cardFrontImage}
                              alt=""
                            />
                          </td>
                          <td>{item.card_name}</td>
                          <td>{item.card_price}</td>
                        </tr>
                      ))}
                      <tr>
                        <td className="fw-bolder"></td>
                        <td>Order Total</td>
                        <td>{totalPrice}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default OrderDetails;
