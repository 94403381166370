import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../../components/layout/AdminHeader";
import AdminSidebar from "../../components/layout/AdminSidebar";
import Footer from "../../components/layout/Footer";
import { getOrders } from "../../actions/orders";
import { setAlert } from "../../actions/setAlert";

function Orders({ getOrders }) {
  const [ordersList, setOrdersList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const data = await getOrders();
        setOrdersList(data.result);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [getOrders]);

  const handleRowClick = (orderId) => {
    navigate(`/admin/orderdetails/${orderId}`);
  };

  console.log(ordersList);

  return (
    <>
      <AdminHeader />
      <section className="dashboard">
        <div className="container mb-5">
          <div className="row my-5 g-0">
            <AdminSidebar />
            <div id="tablesecorder" className="col-md-10">
              <h1>Orders</h1>
              <table id="tablesec" className="table">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Order Number</th>
                    <th scope="col">Date</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Address</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Email</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {ordersList.map((order) => {
                    let orderedItems = [];
                    let totalPrice = 0;

                    try {
                      if (order.ordered_items) {
                        orderedItems = JSON.parse(order.ordered_items);
                        if (!Array.isArray(orderedItems)) {
                          throw new Error("ordered_items is not an array");
                        }
                      }
                      totalPrice = orderedItems.reduce(
                        (total, item) => total + (item.itemTotal || 0),
                        0
                      );
                    } catch (error) {
                      console.error("Error parsing ordered_items:", error);
                    }

                    return (
                      <tr key={order.id} onClick={() => handleRowClick(order.id)} style={{ cursor: 'pointer' }}>
                        <td>{order.id}</td>
                        <td>{order.created_date}</td>
                        <td>{order.first_name}</td>
                        <td>{order.address_line1}</td>
                        <td>{order.phoneNumber}</td>
                        <td>{order.email_id}</td>
                        <td>{totalPrice}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

Orders.propTypes = {
  getOrders: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  orders: state.orders,
});

export default connect(mapStateToProps, { setAlert, getOrders })(Orders);
