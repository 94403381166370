import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import MyCanvasContainer from '../../shop/MyContainer'
import Sidebar from "../../layout/sidebar";
// import { BASE_URL } from "../../../actions/types";
import { connect } from "react-redux";
import { setAlert } from "../../../actions/setAlert";
import { updateUsername } from "../../../actions/profile";
import PasswordVisibilityToggler from "../../../utils/passwordVisibilityToggler";
import passwordValidator from "../../../utils/passwordValidator";
import { changePassword } from "../../../actions/profile";

function Settings({ auth: { user }, setAlert, updateUsername , changePassword}) {
  const [username, setUsername] = useState(null);
  const [url, setUrl] = useState(
    user.username && user ? user.username : user.googleId
  );
  const [passErrors, setPassErrors] = useState();

  const [value, setValue] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordInputType, toggleIcon] = PasswordVisibilityToggler();
  const [confirmPasswordInputType, confirmPasswordToggleIcon] = PasswordVisibilityToggler();
  useEffect(() => {
   
    setPassErrors(passwordValidator(value));
  }, [ value]);

 

  const handleBlur = async () => {
    try {
      // Make an HTTP POST request to your backend endpoint to save the data
      console.log(username);
      await updateUsername(username);
      // setAlert()
      // console.log('Data saved:', response.data);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };
  function handleChangePassword(e) {
    setValue({ ...value, [e.target.name]: e.target.value });
    passwordValidator(value);
  }
const onChangePassword = async(e)=>{
  e.preventDefault();
  // console.log(values);
  console.log(value);
  if (passErrors === "") {
    await changePassword(value.password);
    // navigate("/login");
  } else {
    setAlert(passErrors, "danger");
  }
};

  return (
    <>
      <div id="profile" className="container">
        <div className="row pt-3">
          <div className="col-md-3 mb-4">
            <Sidebar />
          </div>
          <div className="col-md-8 pt-2 mt-5 mt-md-0">
            <div className=" mt-5 pt-1">
              <p className="subtitle mt-4 mb-1">Settings</p>
            </div>
            <div className=" mt-5 pt-1">
              <p className="subtitle mt-4 mb-3">Change Profile URL</p>
            </div>
            {/* <div id="profile" className="input-group">
              <p className="pb-2">Your Profile is live: </p>
              <div className="input-container  text-light">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={url}
                  onChange={(e) => setUsername(e.target.value)}
                  // onBlur={handleBlur}
                />

                <label className="filled">vyldfyre/profile/</label>
              </div>
             
            </div> */}
            <div className="col-md-12">
                <p className="mb-4 position-relative">
                <div className="input-container  text-light">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={url}
                  onChange={(e) => setUsername(e.target.value)}
                  // onBlur={handleBlur}
                />

                <label className="filled">vyldfyre/profile/</label>
              </div>
                </p>
              </div>
            <div className=" d-flex justify-content-end  ">
              <button
                type="submit"
                className="btn btn-primary mt-3 "
                onClick={handleBlur}
              >
                Change Profile URL
              </button>
            </div>
            <div id="signin" className=" mt-5 pt-1">
              <p className="subtitle mt-4 mb-3">Change Password</p>
            </div>
            <div className="row">
              <div className="col-md-6">
                <p className="mb-4 position-relative">
                  <div className="form-item">
                  <span
                    className="passwordToggle"
                    style={{
                      top: "32%",
                      left: "93%",
                      color: "white",
                      zIndex: "10",
                    }}
                  >
                    {toggleIcon}
                  </span>
                  <input
                    type={passwordInputType}
                    className="form-control"
                    // placeholder="Password"
                    value={value.password}
                    onChange={handleChangePassword}
                    name="password"
                    required
                  />
                   <label htmlFor="password"> New Password</label>
                  </div>
                </p>
              </div>
              <div className="col-md-6">
                <p className="mb-4 position-relative">
                <div className="form-item">
                  <span
                    className="passwordToggle"
                    style={{
                      top: "32%",
                      left: "93%",
                      color: "white",
                      zIndex: "10",
                    }}
                  >
                    {confirmPasswordToggleIcon}
                  </span>
                  <input
                    type={confirmPasswordInputType}
                    className="form-control"
                    // placeholder="confirm Password"
                    value={value.confirmPassword}
                    onChange={handleChangePassword}
                    name="confirmPassword"
                    required
                  />
                   <label htmlFor="confirmPassword">Confirm Password</label>
                  </div>
                </p>
              </div>
              <div className=" d-flex justify-content-end  ">
              <button
                type="submit"
                className="btn btn-primary mt-3 "
                onClick={onChangePassword}
              >
                Change Password
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Settings.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  updateUsername: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  // isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,

  // loading: state.auth.loading
});

export default connect(mapStateToProps, { setAlert, updateUsername, changePassword })(Settings);
