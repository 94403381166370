import React, { useRef, useEffect, useState } from "react";
import { fabric } from "fabric";
import images from "../../../utils/imageImports";
import SelectedSocialPlatform from "../../../utils/SelectedSocialPlatform";
function SocialDetailCanvas({ card, flag, getCanvasRef }) {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [selectedCard, setSelectedCard] = useState("");
  const [canvasWidth, setCanvasWidth] = useState(250);
  const [canvasHeight, setCanvasHeight] = useState(400);
  const [textBox, setTextBox] = useState(null);
  const [canvas, setCanvas] = useState(null);
  const [imageObject, setImageObject] = useState(null);
const [val, setVal] = useState('');
  // Update selected card on prop change
  useEffect(() => {
    setSelectedCard(card);
  }, [card]);
  useEffect(()=>{
    const socialDetails = JSON.parse(localStorage.getItem("socialDetails"));
    console.log(socialDetails);
    if(socialDetails){
      setVal(socialDetails);
    }
  },[]);
  // Initialize canvas
  console.log(val)
  useEffect(() => {
    if (!canvasRef.current) return;

    const canvasNew = new fabric.Canvas(canvasRef.current, {
      backgroundColor: selectedCard.backgroundColor || "#000000",
    });
    canvasNew.setWidth(canvasWidth);
    canvasNew.setHeight(canvasHeight);
    setCanvas(canvasNew);

    // Center align canvas
    const containerWidth = canvasRef.current.parentElement.offsetWidth;
    const containerHeight = canvasRef.current.parentElement.offsetHeight;
    const leftOffset = (containerWidth - canvasWidth) / 2;
    const topOffset = (containerHeight - canvasHeight) / 2;
    canvasNew.set({ left: leftOffset, top: topOffset });

    // Load canvas data from local storage
    const textbox = JSON.parse(localStorage.getItem("textbox"));
    setTextBox(textbox);
    const imagebox = JSON.parse(localStorage.getItem("imageObject"));
    setImageObject(imagebox);

  }, [canvasRef, canvasWidth, canvasHeight, selectedCard]);

  // Add textbox to canvas
  useEffect(() => {
    if (!textBox || !canvas) return;

    if (flag === "front") {
      const fabricTextBox = new fabric.Textbox(textBox.text, {
        left: textBox.left,
        top: textBox.top,
        fontFamily: textBox.fontFamily,
        fontSize: textBox.fontSize,
        fill: textBox.fill,
        width: textBox.width,
        height: textBox.height,
        lineHeight: textBox.lineHeight,
        selectable: false,
        visible: textBox.visible,
      });

      canvas.add(fabricTextBox);
    }

  }, [textBox, canvas, flag]);

  // Add image to canvas
  useEffect(() => {
    if (!imageObject || !canvas) return;

    if (flag === "front") {
      fabric.Image.fromURL(imageObject.src, function (img) {
        img.set({
          left: imageObject.left,
          top: imageObject.top,
          angle: imageObject.angle,
          scaleX: imageObject.scaleX,
          scaleY: imageObject.scaleY,
          selectable: false,
          visible: imageObject.visible,
        });

        canvas.add(img);
      });
    }

  }, [imageObject, canvas, flag]);

  // Provide container reference to parent
  useEffect(() => {
    getCanvasRef(containerRef);
  }, [containerRef, getCanvasRef]);

  return (
    <>
      <div className="position-relative">
        <div ref={containerRef} className="canvas-container d-flex align-content-center justify-content-center mt-3">
          <canvas ref={canvasRef} className="canvas"></canvas>

       <SelectedSocialPlatform platform={val} flag={flag} />
          
          {flag === "front" && card && (
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                top: ["3", "4"].includes(card.nfc_icon_placement) ? "calc(100% - 60px)" : "10px",
                right: ["1", "3"].includes(card.nfc_icon_placement) ? "calc(38%)" : "auto",
                left: ["2", "4"].includes(card.nfc_icon_placement) ? "65px" : "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ position: "relative", minWidth: 0, flex: 1 }}>
                <div className="nfc-icon-placement">
                  <img src={images.nfcsymbol} style={{ width: "33px" }} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SocialDetailCanvas;
