import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ShopHeader from "../layout/ShopHeader";
import Footer from "../layout/Footer";
// import Card from "../../utils/Trial";
import FabricEditor2 from "../Example2";
import ConfirmCheckbox from "./ConfirmCheckbox";
import images from "../../utils/imageImports";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import { handleCapture } from "../../utils/handlecapture";
import CircleCanvas from "../../utils/CircleCanvas";
// import { useCart } from "cart";
import TagCanvas from "./tags/TagCanvas";
import BandCanvas from "./bands/BandCanvas";
import RingCanvas from "./rings/RingCanvas";
import socialDetailCanvas from "./SocialDetailCard/socialDetailCanvas";
function ConfirmPage() {
  const navigate = useNavigate();
  const { addItem } = useCart();
  const [cardSide, setCardSide] = useState("front");
  const [card, setCard] = useState("");
  const [background, setBackground] = useState("#000000");
  const [confirm, setConfirm] = useState(false);
  const [canvasRef, setCanvasRef] = useState(null);

  useEffect(() => {
    // Retrieve selected card and background data from localStorage
    const selectedCard = JSON.parse(localStorage.getItem("selectedCard"));
    const cardPrice = parseInt(selectedCard.card_price, 10);

    // Add the price attribute to card state variable
    const updatedCard = { ...selectedCard, price: cardPrice };
    setCard(updatedCard);
    const selectedBackground = JSON.parse(
      localStorage.getItem("backgroundColor")
    );
    setBackground(selectedBackground);
  }, []);

  useEffect(() => {
    // Update card's backgroundColor property when background changes
    if (card) {
      const updatedCard = { ...card, backgroundColor: background };
      setCard(updatedCard);
    }
  }, [background]);
  console.log(card);
  console.log(canvasRef);

  const handleLogin = async () => {
    // console.log("hello")
    const { filename, imageData } = await handleCapture(canvasRef, "front");

    console.log(imageData);
    if (imageData) {
      card.cardFrontImage = imageData;
      card.cardBackImage = images.cardBackImage;
      card.colors = undefined;
    }
    // localStorage.setItem("backCard", images.cardBackImage);
    console.log(imageData);
    console.log(card);
    const token = localStorage.getItem("token");
    if (token) {
      addItem(card);
      navigate("/vyldfyrecard/cart");
    } else {
      addItem(card);
      navigate("/login?from=cart");
    }
  };
  console.log(card);
  console.log(background);

  const handleConfirm = (data) => {
    setConfirm(data);
  };
  const handleCardSide = (side) => {
    console.log(`Card side clicked: ${side}`);
    setCardSide(side);
  };
  const getCanvasRef = (data) => {
    setCanvasRef(data);
  };
  const cardComponents = {
    1: FabricEditor2,
    2: CircleCanvas,
    3: TagCanvas,
    4: BandCanvas,
    5: RingCanvas,
    6: socialDetailCanvas,
  };
  const CardComponent =
    card && cardComponents[card.card_type]
      ? cardComponents[card.card_type]
      : null;
  return (
    <div>
      <ShopHeader />

      <section className="dashboard">
        <div className="container mb-5">
          <div className="row confirm my-5 mt-3">
            <div className="col-md-6 py-2 justify-content-center ">
              {CardComponent ? (
                <>
                  {cardSide === "front" && (
                    <CardComponent
                      card={card}
                      flag="front"
                      getCanvasRef={getCanvasRef}
                    />
                  )}
                  {cardSide === "back" && (
                    <CardComponent
                      background="#000000"
                      card={card}
                      flag="back"
                      getCanvasRef={getCanvasRef}
                    />
                  )}
                </>
              ) : (
                <div className="loading-container" role="status">
                  <img src={images.vyldfyreLogoAnimation} alt="loading" />
                </div>
              )}
            </div>
            <div className="col-md-6 px-0">
              <ConfirmCheckbox handleConfirm={handleConfirm} />
            </div>
          </div>

          <div className="row">
            <div className="p-2 ">
              {card.card_type !== "4" && card.card_type !== "5" && (
                <>
                  <button
                    className="p-2 px-2 me-3 btn btn-secondary"
                    onClick={() => handleCardSide("front")}
                  >
                    Front
                  </button>
                  <button
                    className="p-2 px-3 me-3 btn btn-secondary"
                    onClick={() => handleCardSide("back")}
                  >
                    Back
                  </button>
                </>
              )}

              {confirm && (
                <button
                  onClick={handleLogin}
                  className="p-2 px-2 me-3 btn btn-secondary float-end"
                >
                  Continue
                </button>
              )}
              <Link
                className="p-2 px-3 me-3 btn btn-secondary float-end"
                to={`/vyldfyrefrontcard/${encodeURIComponent(background)}/${
                  card.id
                }`}
              >
                Edit My Design
              </Link>
            </div>
          </div>
          {/* <Card /> */}
        </div>
      </section>
      <br />
      <br />
      <br />
      <br />
      <br />

      <Footer />
    </div>
  );
}

export default ConfirmPage;
