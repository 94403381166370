import axios from "axios";
import { GET_ORDERS, AUTH_ERROR, AXIOS_BASE_URL} from "./types";
import { setAlert } from "./setAlert";
import setAuthToken from "../utils/setAuthToken";

axios.defaults.baseURL=AXIOS_BASE_URL;

export const getOrders =() => async dispatch =>{
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    if (localStorage.token) {
        setAuthToken(localStorage.admin_token);
    }
    
    try {
        const res = await axios.get(`api/cart/checkout/orders`, config);
        console.log(res);
        dispatch({
            type: GET_ORDERS,
            payload: res.data.data,
        })
    //   dispatch(setAlert(res.data.msg));
    return res.data.data;
    } catch (err) {
        dispatch(setAlert(err.response.data.message,'danger'))
        dispatch({
            type: AUTH_ERROR,
            
        })
    }
}
