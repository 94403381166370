import logo from "../images/logo.png";
import loginicon from "../images/loginicon.png";
import innerbg from "../images/innerbg.png";
import googleicon from "../images/google-icon.png";
import footerLogo from "../images/footer-logo.png";
import logonew from "../images/logonew.png";
import mobilelogo from "../images/logosymbol.png";
import camara from "../images/camara.png";
import pencil from "../images/Vector.png";
import facebook from "../images/facebook.png";
import instagram from "../images/instagramnew.png";
import linkedin from "../images/linkedinnew.png";
import youtube from "../images/Youtube.png";
import twitterx from "../images/twitterx.png";
import socialicon from "../images/Ellipse.png";
import coverImage from "../images/Group206.png"
import backImage from "../images/backgrond_icon.png";
import hoverBackImage from "../images/yellowbackgroundicon.png";
import facebooksq from "../images/facebooksquare.png";
import closebtn from "../images/closebtn.png";
import snapchat from "../images/snapchat.png";
import whatsapp from "../images/whatsapp.png";
import messenger from "../images/messenger.png";
import email from "../images/email.png";
import bannerbg from "../images/bannerbg.png"
import githubbg from "../images/githubbg.png"
import vyldfyrenfc from '../images/vyldfyre_nfc.png';
import nfcwithimage from "../images/nfcwithimage.png";
import cardBtn from "../images/cardbtn.png";
import cardBtnBack from "../images/cardbtnback.png";
import favIcon from "../images/favicon.png";
import vyldfyredesign from "../images/vyldfyredesign.png";
import nfcsymbol from "../images/nfcsynbol.png";
import powered  from "../images/powered.png";
import completecard from "../images/completecard.png";
import logosymbol from "../images/logosymbol.png"
import vyldfyreLogoAnimation from "../images/Vyldfyre Logo aniamtion.gif";
import cardBackImage from "../images/cardback_image.png"
import startHere from "../images/start_here.png";
import mobileBanner from "../images/mobilebanner.png";
import nfc_card from "../images/card_nfc.png";
import nfc_tag from "../images/nfc_tag.png";
import nfc_band from "../images/nfc_band.png";
import nfc_ring from "../images/nfc_ring.png";
import homebackground from "../images/homebackbg.png";
import plainband from "../images/plainband.png"
import SDFrontgoogle from "../images/SDCardFront.png";
import SDBackgoogle from "../images/SDCardBack.png";
import SDBackFacebook from "../images/Facebook_Front.png";
import SDBackInstagram from "../images/Instagram_Front.png";
import SDBackTwitter from "../images/Twitter_Front.png";
import SDBackYoutube from "../images/Youtube_Front.png";
import SdBackLinkedin from "../images/Linkedin_Front.png"
const images = {
    logo: logo,
    loginIcon: loginicon,
    innerBg: innerbg,
    googleIcon: googleicon,
    footerLogo: footerLogo,
    logoNew: logonew,
    mobileLogo: mobilelogo,
    camara: camara,
    pencil: pencil,
    facebook: facebook,
    instagram:instagram,
    linkedin: linkedin,
    youtube: youtube,
    twitterx: twitterx,
    socialicon: socialicon,
    coverImage : coverImage,
    backImage : backImage,
    hoverBackImage: hoverBackImage,
    facebooksq: facebooksq,
    closebtn: closebtn,
    snapchat: snapchat,
    whatsapp:whatsapp,
    messenger:messenger,
    email: email,
    bannerbg: bannerbg,
    githubbg: githubbg,
    vyldfyrenfc: vyldfyrenfc,
    nfcwithimage: nfcwithimage,
    cardBtn: cardBtn,
    cardBtnBack: cardBtnBack,
    favIcon : favIcon,
    vyldfyredesign: vyldfyredesign,
    nfcsymbol : nfcsymbol,
    powered : powered,
    completecard : completecard,
    vyldfyreLogoAnimation : vyldfyreLogoAnimation,
    logosymbol: logosymbol,
    cardBackImage: cardBackImage,
    startHere: startHere,
    mobileBanner: mobileBanner,
    nfc_card: nfc_card,
    nfc_band: nfc_band,
    nfc_tag: nfc_tag,
    nfc_ring: nfc_ring,
    homebackground: homebackground,
    plainband: plainband,
    SDFrontgoogle: SDFrontgoogle,
    SDBackgoogle: SDBackgoogle,
    SDBackFacebook: SDBackFacebook,
    SDBackInstagram: SDBackInstagram,
    SDBackTwitter: SDBackTwitter,
    SDBackYoutube: SDBackYoutube,
    SdBackLinkedin: SdBackLinkedin,
  };

  export default images;