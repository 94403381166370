import React, { useState, useEffect, useContext, useRef } from "react";
import CoverPic from "./coverpic";
import ProfilePic from "./profilepic";
import Background from "./Background";
import { Form, FloatingLabel, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import images from "../../utils/imageImports";
import { BASE_URL, AXIOS_BASE_URL } from "../../actions/types";
import PropTypes from "prop-types";
import Sidebar from "../layout/sidebar";
import Mobile from "./Mobile";
import Footer from "../layout/Footer";
import axios from "axios";
import { setAlert } from "../../actions/setAlert";
import { BasicDetailsContext } from "../../utils/providers/RootProvider";
import { updateUsername } from "../../actions/profile";
function BasicDetails({ auth: { user }, setAlert, updateUsername }) {
  const titleRef = useRef(null);
  const [url, setUrl] = useState(
    user.username && user ? user.username : user.googleId
  );
  // console.log(user);
  const [basicDetails, setBasicDetails] = useState({
    name: user.name,
    cover: user.cover_pic,
    profile: user.profile_pic,
    companyName: user.company_name,
    designation: user.designation,
    mobileNumber: user.phn_number,
    email: user.email,
    title: user.about_title,
    paragraph: user.about_paragraph,
    background: user.bg_theme,
  });
  // console.log(basicDetails,"initial");
  const { setData: updateBasicDetailsData } = useContext(BasicDetailsContext);
  useEffect(() => {
    updateBasicDetailsData(basicDetails);
  }, [basicDetails]);

  const getDataFromCoverpic = (data) => {
    if (data) {
      setBasicDetails({ ...basicDetails, cover: data });
    }
    // console.log(basicDetails, "coverpic");
  };
  const getDataFromProfilepic = (data) => {
    if (data) {
      setBasicDetails({ ...basicDetails, profile: data });
    }
    // console.log(basicDetails, "profile pic");
  };

  const getDataFromBackground = (data) => {
    console.log(data, "hello");
    if (data) {
      setBasicDetails({ ...basicDetails, background: data });
    }
    // console.log(basicDetails, "bgthme");
  };

  function handleChange(e) {
    // console.log(e)
    e.preventDefault();
    const { name, value } = e.target;
    setBasicDetails({ ...basicDetails, [name]: value });
  }
  const handleCopy = () => {
    navigator.clipboard.writeText(BASE_URL + "vyldfyre/profile/" + url);
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    console.log(basicDetails);
    try {
      // First
      const res = await axios.put(
        `${AXIOS_BASE_URL}users/updateprofile/${user.id}`,
        basicDetails
      );
      // console.log(res);
      setAlert(res.data.message, "success");
      //  setUpdatedProfile(updateResponse.data);
      // console.log('Update API response:', updateResponse);
    } catch (error) {
      // console.error("Error:", error.message);
      setAlert(error.message, "danger");
    }
  };
  const handleBlur = async () => {
    try {
      // Make an HTTP POST request to your backend endpoint to save the data
      updateUsername(url);
      // setAlert()
      // console.log('Data saved:', response.data);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };
  console.log(basicDetails.paragraph);
  return (
    <div id="profile" className="container">
      <div className="row pt-3">
        <div className="col-md-3 mb-4">
          <Sidebar />
        </div>
        <div className="col-md-6 pt-2 mt-5 mt-md-0">
          <div id="profile" className="input-group">
            <div className="col-md-10">
              <p className="pb-2">Your Profile is live: </p>
            </div>
            <div className="col-md-2 d-flex justify-content-end align-item-end">
              <Link to="/settings" className="linkyellow">
                Update URL
              </Link>
            </div>

            <div className="input-container text-light">
              <input
                type="text"
                defaultValue={url}
                //  onChange={(e) => setUrl(e.target.value)}
                //  onBlur={handleBlur}
              />

              <label className="filled">vyldfyre/profile/</label>
            </div>
            <button
              onClick={handleCopy}
              className="input-group-text2 browse btn"
              variant="outline-secondary"
              id="button-addon2"
            >
              copy url
            </button>
          </div>
          <div id="profile">
            <div className="mt-5">
              <div className="cambg">
                <CoverPic
                  coverpic={basicDetails.cover}
                  sendDataToProfile={getDataFromCoverpic}
                />
                <ProfilePic
                  profilepic={basicDetails.profile}
                  sendDataToProfile={getDataFromProfilepic}
                />
              </div>
            </div>
            <div className=" mt-5 pt-1">
              <p className="subtitle mt-4 mb-3">Basic Details</p>
              <Form.Group>
                <Link></Link>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Full Name"
                  className="mb-2"
                >
                  <Form.Control
                    className="fcgry"
                    value={basicDetails.name}
                    name="name"
                    onChange={handleChange}
                    type="text"
                    placeholder=""
                  />
                </FloatingLabel>
              </Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Designation"
                className="mb-2"
              >
                <Form.Control
                  type="text"
                  name="designation"
                  value={basicDetails.designation}
                  onChange={handleChange}
                  placeholder=""
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Company Name"
                className="mb-2"
              >
                <Form.Control
                  type="text"
                  name="companyName"
                  value={basicDetails.companyName}
                  onChange={handleChange}
                  placeholder=""
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingInput"
                label="Phone Number"
                className="mb-2"
                color="white"
              >
                <Form.Control
                  type="text"
                  name="mobileNumber"
                  value={basicDetails.mobileNumber}
                  onChange={handleChange}
                  maxLength="20"
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Email Id"
                className="mb-3"
              >
                <Form.Control
                  name="email"
                  defaultValue={user.email}
                  // onChange={handleChange}
                  className="inputtext"
                  type="text"
                  readOnly
                />
              </FloatingLabel>
            </div>

            <div className=" mt-4">
              <p className="subtitle my-4">About</p>
              <div className="innrmediabox p-2 mt-1">
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <div className="col-md-11 col-11">
                    <Form.Control
                      ref={titleRef}
                      plaintext
                      onChange={handleChange}
                      name="title"
                      value={basicDetails.title}
                    />
                  </div>
                  <div className="col-md-1 col-1 p-1">
                    <Form.Label>
                      <Link
                        onClick={() => {
                          titleRef.current.focus();
                        }}
                      >
                        <img src={images.pencil} alt="edit" />
                      </Link>
                    </Form.Label>
                  </div>
                  <div className="col-md-11 col-11">
                    <Form.Control
                      plaintext
                      as="textarea"
                      onChange={handleChange}
                      name="paragraph"
                      value={basicDetails.paragraph}
                    />
                  </div>
                  <div className="col-md-1 col-1 p-1">
                    <Form.Label>
                      <Link to="">
                        <img src={images.pencil} alt="edit" />
                      </Link>
                    </Form.Label>
                  </div>
                </Form.Group>
              </div>

              <Background sendDataToProfile={getDataFromBackground} />
            </div>
          </div>
          <Button
            className="btnyellow mt-2 mb-2 p-2 float-end "
            onClick={handleUpdate}
          >
            Save
          </Button>
        </div>

        <Mobile />
        <Footer />
      </div>
    </div>
  );
}

BasicDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  updateUsername: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  // isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,

  // loading: state.auth.loading
});

export default connect(mapStateToProps, { setAlert, updateUsername })(
  BasicDetails
);
