import {
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_AUTH_ERROR,
  ADMIN_USER_LOADED
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("admin_token"),
  adminIsAuthenticated: false,
  adminLoading: false,
  user: null,
};

export default function adminauth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_USER_LOADED:
      return {
        ...state,
        adminIsAuthenticated: true,
        adminLoading: false,
        user: payload,
      };
    case ADMIN_LOGIN_SUCCESS:
      localStorage.setItem("admin_token", payload.token);
      return {
        ...state,
        ...payload,
        adminIsAuthenticated: true,
        adminLoading: false,
      };
    case ADMIN_LOGIN_FAIL:
    case ADMIN_AUTH_ERROR:
      localStorage.removeItem("admin_token");
      return {
        ...state,
        token: null,
        adminIsAuthenticated: false,
        adminLoading: false,
      };
    default:
      return state;
  }
}
