import React, { useState } from "react";
import ShopHeader from "../layout/ShopHeader";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import Footer from "../layout/Footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { checkout } from "../../actions/checkout";
import { setAlert } from "../../actions/setAlert";
import { useCart } from "react-use-cart";
import { useNavigate } from "react-router-dom";

function Checkout({ auth: { user }, setAlert, checkout, cart }) {
  const navigate = useNavigate();
  const { totalUniqueItems, cartTotal, items } = useCart();

  const [formData, setFormData] = useState({
    user_id: user.id,
    fullName: user.name,
    companyname: user.company_name,
    country: "",
    addressLine1: "",
    addressLine2: "",
    town: "",
    state: "",
    zipcode: "",
    phoneNumber: user.phn_number,
    emailId: user.email,
    checkoutText: "",
    orderedItems: JSON.stringify(items),
    promo: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Check for empty fields
    const {
      fullName,
      country,
      addressLine1,
      addressLine2,
      town,
      state,
      zipcode,
      phoneNumber,
      emailId,
    } = formData;

    if (
      !fullName ||
      !country ||
      !addressLine1 ||
      !addressLine2 ||
      !town ||
      !state ||
      !zipcode ||
      !phoneNumber ||
      !emailId
    ) {
      setAlert("Please fill in all required fields", "danger");
      return;
    }

    if (items.length > 0) {
      try {
        await checkout(formData);
        navigate("/vyldfyrecard/orderconfirm");
        localStorage.removeItem("frontCard");
        localStorage.removeItem("selectedBackground");
        localStorage.removeItem("textbox");
        localStorage.removeItem("backgroundColor");
        localStorage.removeItem("react-use-cart");
      } catch (error) {
        console.error("Error saving data:", error);
      }
    } else {
      setAlert("No Items Selected", "danger");
    }
  };


  return (
    <div>
      <ShopHeader cartTotalItems={totalUniqueItems} />
      <section className="dashboard">
        <div className="container mb-5">
          <div className="row my-5 g-0">
            <div>
              <h5 className="yellowtext mb-4"> Checkout </h5>
              <hr />
              <div>
                <p>
                  {" "}
                  <FontAwesomeIcon className="yellowtext" icon={faGift} /> Have
                  a Coupon?{" "}
                  <Link className="text-light underline-link">
                    click here to enter your code
                  </Link>
                </p>
              </div>
              <hr />
            </div>
            <form className="row g-3" onSubmit={handleSubmit}>
              <div id="profile" className="col-md-6">
                <h6>Billing Details</h6>

                <div className="col-md-6">
                  <label htmlFor="fullName" className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="companyname" className="form-label">
                    Company Name (optional)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyname"
                    name="companyname"
                    value={formData.companyname}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="country" className="form-label">
                    Country/region
                  </label>
                  <select
                    id="country"
                    name="country"
                    className="form-select"
                    value={formData.country}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Country</option>
                    <option value="india">India</option>
                    <option value="dubai">Dubai</option>
                    <option value="uk">UK</option>
                    <option value="usa">United States</option>
                    <option value="australia">Australia</option>
                  </select>
                </div>
                <div className="col-12">
                  <label htmlFor="addressLine1" className="form-label">
                    Address Line 1
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="addressLine1"
                    name="addressLine1"
                    value={formData.addressLine1}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="addressLine2" className="form-label">
                    Address Line 2
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="addressLine2"
                    name="addressLine2"
                    value={formData.addressLine2}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="town" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="town"
                    name="town"
                    value={formData.town}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <label htmlFor="state" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <label htmlFor="zipcode" className="form-label">
                    Zip
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="zipcode"
                    name="zipcode"
                    value={formData.zipcode}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="row">
                <div className="col-md-6 ">
                  <div className="has-validation">
                  <label htmlFor="phoneNumber" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                  </div>
                   <div class="invalid-feedback">
    Please enter a mobileNumber.
  </div>
                </div>
               
                <div className="col-md-6">
                  <label htmlFor="emailId" className="form-label">
                    Email id
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="emailId"
                    name="emailId"
                    value={formData.emailId}
                    onChange={handleChange}
                    required
                  />
                </div>
                </div>
                <div className="col-12">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="promo"
                      name="promo"
                      checked={formData.promo}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="promo">
                      Sign me up to receive special deals, email updates, and
                      news (optional)
                    </label>
                  </div>
                </div>
              </div>
              <div id="profile" className="col-md-5 ms-5">
                <div className="col-12">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="differentAddress"
                      name="differentAddress"
                      checked={formData.differentAddress}
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="differentAddress"
                    >
                      Ship to different address? (optional)
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <label htmlFor="checkoutText" className="form-label">
                    Additional Information
                  </label>
                  <textarea
                    className="form-control"
                    id="checkoutText"
                    rows="3"
                    name="checkoutText"
                    value={formData.checkoutText}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div id="products" className="products">
                  <h1>Ordered Items</h1>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" className="ms-5 justify-content-center">
                          Product Name
                        </th>
                        <th scope="col">Price</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, id) => (
                        <tr key={id}>
                          <th scope="row">
                            <img
                              className="cartimage me-2 ms-2"
                              src={item.cardFrontImage}
                              alt={item.card_name}
                            />
                            {item.card_name}
                          </th>
                          <td>${parseInt(item.card_price)} USD</td>
                          <td>{item.quantity}</td>
                          <td>
                            ${parseInt(item.card_price) * item.quantity} USD
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <span className="float-end">Cart Total: ${cartTotal}</span>
                </div>
                <div className="col-md-12 mt-5  text-md-end">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Place Order
                  </button>
                </div>
                </div>
              </form>
            
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

Checkout.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  checkout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, checkout })(Checkout);
