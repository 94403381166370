import React from 'react'
import AdminHeader from '../../components/layout/AdminHeader'
import AdminSidebar from '../../components/layout/AdminSidebar'
import Footer from '../../components/layout/Footer'
function CreateTag() {
  return (
    // <>
    // <AdminHeader />
    // <section className="dashboard">
    //   <div className="container mb-5">
    //     <div className="row my-5 g-0">
    //      <AdminSidebar />
    //       <div className="col-md-10">
    //         <h1>Create Card</h1>

    //         <div className="row mt-5">
    //           <h6 className="pb-4">Basic details</h6>
    <div>hello</div>
  )
}

export default CreateTag