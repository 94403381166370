import React from 'react';
import { Navigate } from 'react-router-dom';

const RoleBasedRoute = ({ element, requiredRole, userRole }) => {
  if (!userRole) {
    if (requiredRole === "user") {
      return <Navigate to="/login" />;
    } else {
      return <Navigate to="/admin/login" />;
    }
  }

  if (requiredRole !== userRole) {
    return <Navigate to="/unauthorized" />;
  }

  return element;
};

export default RoleBasedRoute;
