import React, { useState } from "react";
import images from "../../utils/imageImports";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";

function RightSideBar({ handleCardSide }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedSide, setSelectedSide] = useState("front"); // State to track selected side

  const handleEditClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleSideClick = (side) => {
    setSelectedSide(side);
    handleCardSide(side);
  };
console.log(selectedSide);
  return (
    <>
      <div className="col-md-2">
        <ul className="navbar-nav d-flex align-items-center justify-content-center mt-5 list-unstyled">
          <li className={`cardbtn p-3 mb-1 ${selectedSide === "front" ? "highlight" : ""}`}>
            <Link onClick={() => handleSideClick("front")}>
              <p className="text-light">Front</p>
              <img src={images.cardBtn} />
            </Link>
          </li>
          <li className={`cardbtn p-3 pt-0 ${selectedSide === "back" ? "highlight" : ""}`}>
            <Link onClick={() => handleSideClick("back")}>
              <p className="text-light">Back</p>
              <img src={images.cardBtnBack} />
            </Link>
          </li>
        </ul>
      </div>

      {sidebarOpen && (
        <div className="sidebar-right">
          <div className="sidebar-content">
            <h5 className="mt-3">Edit back design</h5>
            <Link className="text-light" onClick={handleEditClick}>
              <FontAwesomeIcon className="float-end" size="2x" icon={faXmark} />
            </Link>
            <div className="row design-card-container">
              <div className="backdesigncard mb-2 col-md-6 me-2">
                <Link onClick={() => handleSideClick("back")}>
                  <img className="mt-2 p-2" src={images.favIcon} />
                  <p>VYLDFYRE Default Design</p>
                </Link>
              </div>
              <div className="backdesigncard col-md-6 mb-2 me-2">
                <FontAwesomeIcon className="mt-2 p-2" size="2x" icon={faArrowUpFromBracket} />
                <p>Upload your Design</p>
              </div>
              <div className="backdesigncard col-md-6 mb-2 me-2">
                <FontAwesomeIcon className="mt-2 p-2" size="2x" icon={faSquare} />
                <p>Change to blank Design</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RightSideBar;
