import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    USERNAME_UPDATE,
  } from "../actions/types";
  
  const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: false,
    loading: true,
    user: null,
  };
  
  export default function auth(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case USER_LOADED:
        return {
          ...state,
          isAuthenticated: true,
          loading: false,
          user: payload,
        };
      // case REGISTER_SUCCESS:
      case LOGIN_SUCCESS:
        localStorage.setItem("token", payload.token);
        return { ...state, ...payload, isAuthenticated: true, loading: false };
      //  case REGISTER_FAIL:
      case LOGIN_FAIL:
      case AUTH_ERROR:
        localStorage.removeItem("token");
        return { ...state, token: null, isAuthenticated: false, loading: false };
      case USERNAME_UPDATE:
        return console.log("updated");
  
      default:
        return state;
    }
  }
  