import React, { useState } from "react";
import AdminHeader from "../../components/layout/AdminHeader";
import Footer from "../../components/layout/Footer";
import { Link } from "react-router-dom";
import images from "../../utils/imageImports";
import { connect, useDispatch } from "react-redux";
import { useImmer } from "use-immer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faAngleDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { BlockPicker } from "react-color";

import PropTypes from "prop-types";
import axios from "axios";
import { submitCard } from "../../actions/adminCards";
import { setAlert } from "../../actions/setAlert";
import AdminSidebar from "../../components/layout/AdminSidebar";
import defaultColors from "../../utils/defaultColors";
function CreateCard({ setAlert, submitCard }) {
  const dispatch = useDispatch();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState("");
  const [val, setVal] = useState(["#000000"]);
  const [selectedInput, setSelectedInput] = useState(null);
  const [basicDetails, setBasicDetails] = useState({
    cardName: "",
    cardPrice: "",
    cardType:"",
    backgroundImage: "",
    nfcIconPlacement: "",
    selectedColors: val,
    placeHolderText: "",
  });
  const [itemColors, setItemColors] = useState(
    Array.from({ length: val.length }, () => "")
  );
  const [palette, setPalette] = useImmer({
    collection: [...defaultColors],
    activeColorId: defaultColors.find((color) => color.active).id,
    currentColor: defaultColors.find((color) => color.active).default,
  });
  const presetColors = palette.collection.map((color) => color.default);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setBasicDetails({ ...basicDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    console.log(basicDetails);
    e.preventDefault();

    const formData = new FormData();
    formData.append("cardName", basicDetails.cardName);
    formData.append("cardPrice", basicDetails.cardPrice);
    formData.append("cardType", basicDetails.cardType)
    formData.append("nfcIconPlacement", basicDetails.nfcIconPlacement);
    formData.append("placeholderText", basicDetails.placeHolderText);
    formData.append("background-image", basicDetails.backgroundImage);
    formData.append(
      "selectedColors",
      JSON.stringify(basicDetails.selectedColors)
    );
    try {
  await submitCard(formData)
  
    } catch (err) {
      console.log(err);
      console.log(err.response.data.error);
      // dispatch(setAlert(err.response.data.error, 'danger '))
      // dispatch(setAlert(err.response.data.error, "danger"));
    }
  };
  const handleColor = (id) => (updatedColor) => {
    const updatedColors = [...basicDetails.selectedColors];
    updatedColors[id] = updatedColor.hex; // Update the color at the specified index
    setBasicDetails((prevState) => ({
      ...prevState,
      selectedColors: updatedColors,
    }));
    setColor(updatedColor.hex);
  };
  console.log(color);

  const handleChangeColor = (id) => {
    console.log(id);
    setShowColorPicker(!showColorPicker);
    setSelectedInput(id);
  };
  const handleAdd = () => {
    setBasicDetails((prevState) => ({
      ...prevState,
      selectedColors: [...prevState.selectedColors, ...val],
    }));
  };
  //   console.log(basicDetails.selectedColors);
  const handleImageUpload = (e) => {
    console.log(e.target.files);
    setBasicDetails({ ...basicDetails, [e.target.name]: e.target.files[0] });
  };
  const handleDelete = (id) => {
    const delval = [...basicDetails.selectedColors];
    delval.splice(id, 1);
    setBasicDetails({ ...basicDetails, selectedColors: delval });
  };
  return (
    <>
      <AdminHeader />
      <section className="dashboard">
        <div className="container mb-5">
          <div className="row my-5 g-0">
           <AdminSidebar />
            <div className="col-md-10">
              <h1>Create Card</h1>

              <div className="row mt-5">
                <h6 className="pb-4">Basic details</h6>
                <div className="col-md-4">
                  <div className="form-item mb-3">
                    <input
                      type="text"
                      id="cardname"
                      name="cardName"
                      value={basicDetails.cardName}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                    />
                    <label htmlFor="cardname">Card name</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-item mb-3">
                    <input
                      type="text"
                      id="cardprice"
                      name="cardPrice"
                      value={basicDetails.cardPrice}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                    />
                    <label htmlFor="cardprice">Card price</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-item mb-3">
                    <select
                      id="select"
                      className="dropdown"
                      name="cardType"
                      value={basicDetails.cardType}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                    >
                      <option  disabled>Vyldfyre Themed NFC card</option>
                      <option value="1">Digital Business Plain card</option>
                      <option value="2"> NFC coin </option>
                      <option value="3">NFC Tag</option>
                      <option  disabled>Vyldfyre Themed NFC card</option>
                      <option value="3">NFC Band</option>
                      <option value="4">NFC Ring</option>
                      <option value="5">Digital Social Media Card</option>



                     
                    </select>
                    <label htmlFor="select">NFC Card Type</label>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <h6 className="pb-4">Colour variants</h6>
                {basicDetails.selectedColors.map((item, id) => (
                  <div key={id} className="col-md-4">
                    <div className="form-item mb-3">
                      <input
                        type="text"
                        id={`cardname-${id}`}
                        value={item}
                        autoComplete="off"
                        // onChange={()=>handleChangeColor()}
                        onClick={() => handleChangeColor(id)}
                        required
                      />
                      <div
                        className="colorboxpick"
                        style={{ background: item }}
                      >
                        <Link onClick={() => handleChangeColor(id)}>
                          <FontAwesomeIcon icon={faAngleDown} />{" "}
                        </Link>
                      </div>
                      <span className="delete-icon">
                        <FontAwesomeIcon
                          onClick={() => handleDelete(id)}
                          icon={faXmark}
                        />
                      </span>
                      {showColorPicker && selectedInput === id && (
                        <BlockPicker
                          color={itemColors[id]}
                          className="hover position-absolute zindex-100"
                          onChange={handleColor(id)}
                        />
                      )}
                    </div>
                  </div>
                ))}

                <div className="col-md-4">
                  <div className="form-item mb-3">
                    <Link onClick={handleAdd}> + add new color </Link>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <h6 className="pb-4">Background image</h6>
                <div className="col-md-4 d-flex flex-column mb-4">
                  <label htmlFor="background-image" className="uploadbtn">
                    <i>
                      <FontAwesomeIcon icon={faArrowUpFromBracket} />
                    </i>{" "}
                    Upload background image
                  </label>
                </div>
                <div className="col-md-5">
                  {basicDetails.backgroundImage && (
                    <img
                      src={URL.createObjectURL(basicDetails.backgroundImage)}
                      className="img-fluid w-100"
                      alt=""
                    />
                  )}
                </div>
                <input
                  type="file"
                  id="background-image"
                  name="backgroundImage"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e)}
                  style={{ display: "none" }} // Hide the input element
                />
              </div>
              <div className="row mt-5">
                <h6 className="pb-4">NFC icon & Custom Field</h6>
                <div className="col-md-4">
                  <div className="form-item mb-3">
                    <select
                      id="select"
                      className="dropdown"
                      name="nfcIconPlacement"
                      value={basicDetails.nfcIconPlacement}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                    >
                      <option value="1">Top Right</option>
                      <option value="2">Top Left</option>
                      <option value="3">Bottom Right</option>
                      <option value="4">Bottom Left</option>
                    </select>
                    <label htmlFor="select">NFC icon placement</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-item mb-3">
                    <input
                      type="text"
                      id="cstfield"
                      name="placeHolderText"
                      value={basicDetails.placeHolderText}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                    />
                    <label htmlFor="cstfield">
                      Custom field placeholder text
                    </label>
                  </div>
                </div>
              </div>
              <div id="backcarddtls" className="row mt-5">
                <h6 className="pb-4">Back card details</h6>
                <div className="col-md-12 mb-4">
                  <div className="checkboxtxt">
                    <input id="checkbox1" type="checkbox" />
                    <label htmlFor="checkbox1">include back</label>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="checkboxtxt">
                    <input id="checkbox2" type="checkbox" />
                    <label htmlFor="checkbox2">Blank design</label>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="checkboxtxt">
                    <input id="checkbox3" type="checkbox" />
                    <label htmlFor="checkbox3">Duplicate front design</label>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="checkboxtxt">
                    <input id="checkbox4" type="checkbox" />
                    <label htmlFor="checkbox4">Upload own design</label>
                  </div>
                </div>
              </div>
              <div className="row my-5 mt-5">
                <div className="col-md-8"></div>
                <div className="col-md-4 mt-5 text-md-end">
                  <a href="#" className="cansbtbtns">
                    {" "}
                    Cancel{" "}
                  </a>{" "}
                  <Link onClick={handleSubmit} className="cansbtbtns ms-3">
                    {" "}
                    Submit{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
CreateCard.propTypes = {
 submitCard: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  // setAlert: PropTypes.func.isRequired,
};

export default connect(null, { submitCard,  setAlert })(CreateCard);
