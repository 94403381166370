import React from "react";
import images from "../../utils/imageImports";
import { Link } from "react-router-dom";
import store from "../../store";
import { ADMIN_AUTH_ERROR } from "../../actions/types";
function AdminHeader() {
  const handleLogout = () => {
    store.dispatch({
      type: ADMIN_AUTH_ERROR,
    });
  };
  return (
    <div>
      <div className="container mb-5">
        <div className="row ">
          <div className="col-md-5 pt-3 my-4 my-md-0">
            <Link href="index.html">
              <img src={images.logo} className="img-fluid" alt="" />
            </Link>
          </div>
          <div id="dashbrdetails" className="col-md-7 pt-3 ">
            <ul className="nav flex-row justify-content-md-end">
              <li>
                <Link href="#">
                  <img src={images.myacc} alt="" /> Admin
                </Link>
              </li>
              <li>
                <Link onClick={handleLogout}>Logout</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminHeader;
