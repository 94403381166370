import React from 'react'
import images from '../../utils/imageImports';
import { Link } from 'react-router-dom';

function AdminLoginHeader() {
  return (
    <div>
        <header>
        <div className="container-fluid">
            <div className="d-none d-sm-block">
            <div className="row">
                <div className="col-md-6 ps-5 pt-3">
                    <Link href="index.html"><img src={images.logo} className="img-fluid" alt="" /></Link>
                </div>
                <div className="col-md-6 pt-3 text-end pe-5">
                      <Link to="/admin/login" className="signbtn me-5">Sign up</Link> 
                </div>
            </div> 
        </div>
        {/* <!-- For mobile resposive -->  */}
        <div className="d-md-none">
            <div className="row mb-5">
                <div className="col-md-12 pt-3">             
                        <Link href="index.html"><img src="assets/images/logo.png" width="150" className="img-fluid" alt="" /></Link>
                        <Link to="/admin/login" className="signbtn">Sign in</Link>             
                </div>
            </div> 
        </div> 
         {/* <!-- end mobile resposive -->       */}
        </div>
    </header>
    </div>
  )
}

export default AdminLoginHeader