import React, { useState, useEffect } from "react";
import images from "./imageImports";
function SelectedSocialPlatform({ platform, flag }) {
  const [selectedPlatform, setSelectedPlatform] = useState(platform);
  console.log(platform);
  useEffect(()=>{
    const socialDetails = JSON.parse(localStorage.getItem("socialDetails"));
setSelectedPlatform(socialDetails);
  },[])
  if(platform.socialmedia_name){
    localStorage.setItem("socialDetails",JSON.stringify(selectedPlatform))
  }
  
  const platformImages = {
    facebook: images.SDBackFacebook,
    twitter: images.SDBackTwitter,
    instagram: images.SDBackInstagram,
    linkedin: images.SdBackLinkedin,
    google: images.SDBackgoogle,
  };
  console.log(images.SDBackgoogle);
console.log(flag);
  return (
    <div id="image-container">
      {platform && flag == "back" && (
        <img
          id="platform-image"
          // className='z-index-10'
          src={platformImages[platform.socialmedia_name]}
          alt={`${selectedPlatform.socialmedia_name} logo`}
        />
      )}
    </div>
  );
}

export default SelectedSocialPlatform;
