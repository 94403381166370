import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleXmark, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { useImmer } from "use-immer";
import Cropper from "react-easy-crop";
import { BlockPicker } from "react-color";
import ColorPicker, { useColorPicker } from "react-best-gradient-color-picker";
import { updateBgTheme } from "../../actions/profile";
import getCroppedImg from "../../utils/crop";
import blobUrlToImageFile from "../../utils/datatofile";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const defaultColors = [
  {
    id: 1,
    name: "color1",
    default: "#18A2AD",
    hex: null,
    rgb: null,
    active: true,
  },
  {
    id: 2,
    name: "color2",
    default: "#FF3838",
    hex: null,
    rgb: null,
    active: false,
  },
  {
    id: 3,
    name: "color3",
    default: "#152532",
    hex: null,
    rgb: null,
    active: false,
  },
  {
    id: 4,
    name: "color4",
    default: "#2ECC71",
    hex: null,
    rgb: null,
    active: false,
  },
  {
    id: 5,
    name: "color5",
    default: "#e9dd41",
    hex: null,
    rgb: null,
    active: false,
  },
];

function Background({ sendDataToProfile, updateBgTheme, auth: { user } }) {
  // const [backGround, setBackgraound] = useState("")
  const [color, setColor] = useState('');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showGradientPicker, setShowGradientPicker] = useState(false);
  const [coverShow, setCoverShow] = useState(false);
  const [gradient, setgradient] = useState();
  const [images, setimages] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState("");
  const { setSolid, setGradient } = useColorPicker(color, setColor);
  const [croppedBgFile, setCroppedBgFile] = useState("");

  const [palette, setPalette] = useImmer({
    collection: [...defaultColors],
    activeColorId: defaultColors.find((color) => color.active).id,
    currentColor: defaultColors.find((color) => color.active).default,
  });
  const presetColors = palette.collection.map((color) => color.default);

  useEffect(() => {
    if (gradient) {const formData = new FormData();
      formData.append("bg_theme", gradient);
      
       updateBgTheme(formData, user.id);
      //  console.log(gradient);
      sendDataToProfile(gradient);
    } else if (images) {
      sendDataToProfile(images);
    } else {
      const formData = new FormData();
      formData.append("bg_theme", color);
     updateBgTheme(formData, user.id);
      sendDataToProfile(color);
    }
  }, [color, gradient, images]);

  const handleGradient = async (newColor) => {
    console.log("newColor", newColor);
    const {
      // rgb: { r, g, b, a },
      hex,
    } = newColor;

    setPalette((draft) => {
      const findColorById = draft.collection.find(
        (color) => color.id === draft.activeColorId
      );

      // findColorById.hex = hex;
      findColorById.rgb = newColor;
      draft.currentColor = newColor;
    });
    setgradient(palette.currentColor);
    // console.log(gradient);
    setColor("");
    setimages("");
  };

  const onChange = (e) => {
    e.preventDefault();
    const fileArray = Array.from(e.target.files).map((file) =>
      URL.createObjectURL(file)
    );
    // console.log(fileArray[0]);
    const c = fileArray[0];
    setimages(...images, c);
    // console.log(images)
    Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));

    // console.log(images)
    setColor("");
    setGradient("");
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea);
    //     console.log(croppedAreaPixels)
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const saveChangesCover = async () => {
    console.log(croppedAreaPixels);
    console.log("hello");
    const croppedImageUrl = await getCroppedImg(images, croppedAreaPixels);
    console.log(croppedImageUrl);
    const imageFile = await blobUrlToImageFile(
      croppedImageUrl,
      "background.jpg"
    );
    setCroppedBgFile(imageFile);
    const imageUrl = URL.createObjectURL(imageFile);
    const formData = new FormData();

    console.log(imageFile);
    formData.append("bg_theme", imageFile);
    try {
      updateBgTheme(formData, user.id);
      setimages(imageFile);
    } catch (err) {
      console.log("error uploading");
    }
    
    setCoverShow(false);
    
    setColor("");
    setgradient("");
  };

  const handleColor =async (updatedColor) => {
    setColor(updatedColor.hex); 
  
    setgradient("");
    setimages("");
  };

  const handleImageClick = () => {
    // image.current.click();
    setCoverShow(true);
    setShowGradientPicker(false);
    setShowColorPicker(false);
  };
  return (
    <div className="row mt-3 mb-5 px-2">
      <p className="subtitle my-4">Background</p>

      <div className=" innrmediabox  p-3 pe-5 ps-4">
        <div className="row">
          <div className="col-md-3 col-4">
            <div className="card">
              <div className="mobbackg" style={{ backgroundColor: color }}>
                <div className="hover">
                  <div className="container-color">
                    <input className="d-none" />

                    {showColorPicker ? (
                      <FontAwesomeIcon
                        className="roundadd"
                        onClick={() => {
                          setShowColorPicker(!showColorPicker);
                          setShowGradientPicker(false);
                        }}
                        icon={faCircleXmark}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="roundadd"
                        onClick={() => {
                          setShowColorPicker(!showColorPicker);
                          setShowGradientPicker(false);
                        }}
                        icon={faCirclePlus}
                      />
                    )}

                    <span id="value"></span>
                  </div>
                </div>
              </div>
            </div>
            {showColorPicker && (
              <BlockPicker
                color={color}
                className="hover position-absolute"
                name={color}
                onChange={handleColor}
              />
            )}

            <p className="f14 text-center pt-4 text-white">Flat colour</p>
          </div>
          <div className="col-md-3 col-4">
            <div className="card">
              <div className="mobgrad" style={{ background: gradient }}>
                <div className="hover">
                  <div className="container-color">
                    <input className="d-none" />
                    <span id="value"></span>
                    {showGradientPicker ? (
                      <FontAwesomeIcon
                        className="roundadd"
                        onClick={() => {
                          setShowGradientPicker(!showGradientPicker);
                          setShowColorPicker(false);
                        }}
                        icon={faCircleXmark}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="roundadd"
                        onClick={() => {
                          setShowGradientPicker(!showGradientPicker);
                          setShowColorPicker(false);
                        }}
                        icon={faCirclePlus}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {showGradientPicker && (
              <ColorPicker
                className="hover position-absolute "
                style={{background:"#101010", padding:'5px'}}
                value={palette.currentColor}
                onChange={handleGradient}
                presets={presetColors}
                hideColorTypeBtns
                // hideAdvancedSliders={true}
                // hideColorGuide={true}
                // hideInputType={true}
                // hideControls={true}
                // hideEyeDrop={true}
                // hideColorTypeBtns
              />
            )}

            <p className="f14 text-center pt-4 text-white">Gradient</p>
          </div>
          <div className="col-md-3 col-4 ">
            <div className="card">
              <div className="picture-container">
                <div className="picture">
                  {/* <input
                  type="file"
                  name="file"
                  id="wizard-picture"
                  onChange={handleChange}
                /> */}
                  <div className="hover">
                    <Link onClick={handleImageClick}>Choose Image</Link>
                  </div>
                </div>
                <Modal
                  show={coverShow}
                  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                  onHide={() => {
                    setCoverShow(false);
                  }}
                >
                 <Modal.Header style={{ justifyContent: "space-between", alignItems: "center" }}className='d-flex bg-light' >
            <Modal.Title className="text-dark">Background Theme</Modal.Title>
            <Link
              className=" text-dark btn btn-link"
              onClick={() => {
                setCoverShow(false);
              }}
            >
              <FontAwesomeIcon className="float-end" icon={ faXmark}/>
            </Link>
          </Modal.Header>
                  <Modal.Body
                    style={{ backgroundColor: "white" }}
                    className="container"
                  >
                    <div className="container-fluid">
                      <div className="row flex-nowrap">
                        <div className="d-flex flex-wrap align-items-center justify-content-center">
                          <h5 style={{ color: "black" }}>
                            Showcase your personality,intrest,team moments
                          </h5>
                          <h5 style={{ color: "black" }}>
                            {" "}
                            or notable milestimes
                          </h5>
                          <p style={{ color: "#969696" }}>
                            A good background photo will help you stand out
                          </p>
                          <div>
                            <input
                              id="imageInput"
                              type="file"
                              className="mb-3"
                              accept=".png, .jpg, .jpeg"
                              onChange={onChange}
                              style={{
                                padding: "10px",

                                display: "none",
                                // Green background color
                                color: "white", // White text color
                                border: "none", // No border
                                borderRadius: "2px",
                                cursor: "pointer",
                              }}
                            />
                            <label
                              htmlFor="imageInput"
                              className="mb-3 mt-3 col "
                              style={{
                                backgroundColor: "black",
                                color: "white",
                                position: "relative",
                                padding: "10px 15px",
                                cursor: "pointer",
                                border: "none",
                                borderRadius: "2px",
                              }}
                            >
                              Choose Image
                            </label>
                          </div>
                          <div className="cropper-container ">
                            <Cropper
                              image={images}
                              crop={crop}
                              showGrid={false}
                              aspect={2 / 3}
                              onCropChange={onCropChange}
                              onCropComplete={onCropComplete}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer  className="d-flex flex-wrap align-items-center justify-content-center bg-light " >
            <div className="d-flex ">
              <button
                className="me-2 p-1 modal-button"
                variant="outline-dark"
                onClick={() => {
                  setCoverShow(false);
                }}
              >
                Change Photo
              </button>
              <button className="modal-button-apply p-1" variant="dark" onClick={saveChangesCover}>
                Apply
              </button>
            </div>
          </Modal.Footer>
                </Modal>
              </div>
            </div>
            <p className="f14 text-center pt-4 text-white">Image</p>
          </div>
        </div>
      </div>
    </div>
  );
}
Background.propTypes = {
  updateBgTheme: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { updateBgTheme })(Background);
