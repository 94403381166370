import React from "react";
import { Fragment } from "react";
import images from "../../utils/imageImports";
import { NavLink, Link } from "react-router-dom";
import { AUTH_ERROR } from "../../actions/types";
import store from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faGear, faChartLine ,faRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";

function Sidebar({ auth: { user }, handleComponentChange }) {
  const menuItem = [
    {
      path: "/profile",
      name: "Profile",
      icon: <FontAwesomeIcon icon={faUser} />,
      account_type: 1,
      dropdownItems: [
        { path: "/profile/basicdetails", name: "Basic Details" },
        { path: "/profile/gallery", name: "Gallery" },
        { path: "/profile/social-details", name: "Social Details" },
      ],
    },
    {
      path: "/analytics",
      name: "Analytics",
      icon: <FontAwesomeIcon icon={faChartLine} />,
    },
    {
      path: "/settings",
      name: "Settings",
      icon: <FontAwesomeIcon icon={faGear} />,
    },
   {
    path: "/login",
    name:"Logout",
    icon: <FontAwesomeIcon icon={faRightFromBracket} />,
   },
  ];

  const handleLogout = () => {
    store.dispatch({
      type: AUTH_ERROR,
    });
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="list-group border-0 rounded-0 text-sm-start">
          <div className="l-navbar " id="nav-bar">
            <div className="nav-item nav-brand">
              <Link to="/">
              <picture>
                <source
                  media="(min-width: 991.98px)"
                  srcSet={images.logoNew}
                  alt="logo"
                />

                <img src={images.mobileLogo} alt="Logo" />
              </picture>
              </Link>
            </div>
            <div className="d-none d-sm-block">
              <div className="mt-5" id="navbarSupportedContent">
                <ul className="navbar-nav mx-3">
                  <li className="nav-item dropdown"></li>
                  {menuItem.map((item, id) => (
                    <Fragment key={id}>
                      {item.dropdownItems ? (
                        <div
                          className="accordion"
                          id={`accordionPanelsStayOpenExample-${id}`}
                        >
                          <div>
                            <h2
                              className="accordion-header"
                              id={`panelsStayOpen-headingOne-${id}`}
                            >
                              <button
                                className="accordion-button nav-link link"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#panelsStayOpen-collapseOne-${id}`}
                                aria-expanded="true"
                                aria-controls={`panelsStayOpen-collapseOne-${id}`}
                              >
                                <div className="row-item icon">{item.icon}</div>
                                <div className="row-item link_text">
                                  {item.name}
                                </div>
                              </button>
                            </h2>
                            <div
                              id={`panelsStayOpen-collapseOne-${id}`}
                              className="accordion-collapse collapse show"
                              aria-labelledby={`panelsStayOpen-headingOne-${id}`}
                            >
                              <div className="accordion-body">
                                <ul>
                                  {item.dropdownItems.map(
                                    (dropdownItem, index) => (
                                      <div key={index}>
                                        <NavLink
                                          to={dropdownItem.path}
                                          className="dropdown-item nav-link link"
                                          activeClassName="active"
                                        >
                                          <div className="row-item link_text">
                                            {dropdownItem.name}
                                          </div>
                                        </NavLink>
                                      </div>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        item.name === "Logout" ? (
                          <NavLink
                            to={item.path}
                            onClick={handleLogout}
                            className="nav-link link"
                            activeClassName="active"
                          >
                            <div className="row-item icon">{item.icon}</div>
                            <div className="row-item link_text">{item.name}</div>
                          </NavLink>
                        ) : (
                          <NavLink
                            to={item.path}
                            className="nav-link link"
                            activeClassName="active"
                          >
                            <div className="row-item icon">{item.icon}</div>
                            <div className="row-item link_text">{item.name}</div>
                          </NavLink>
                        )
                      )}
                    </Fragment>
                  ))}
                </ul>
              </div>
            </div>
            <div className="d-md-none">
              <div className="mb-5" id="navbarSupportedContent">
                <div className="ms-3 px-4 mb-5">
                  {menuItem.map((item, index) => (
                    <Fragment key={index}>
                      {item.dropdownItems ? (
                        <div className="subnav">
                          <button className="subnavbtn">
                            <i className="row-item icon2">{item.icon}</i>
                            <p className="row-item link_text2 mt-3">{item.name}</p> 
                          </button>
                          <div className="subnav-content">
                            {item.dropdownItems.map((dropdownItem, id) => (
                              <a key={id} href={dropdownItem.path}>
                                {dropdownItem.name}
                              </a>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <NavLink
                          to={item.path}
                          className="nav-link link2"
                          activeClassName="active"
                        >
                          <div className="row-item icon2">{item.icon}</div>
                          <div className="row-item link_text2">{item.name}</div>
                        </NavLink>
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

Sidebar.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Sidebar);
