import React, { useRef, useEffect, useState } from "react";
import { fabric } from "fabric";
import images from "../../../utils/imageImports";
import { AXIOS_BASE_URL } from "../../../actions/types";


const TagCanvas = ({ card, flag, getCanvasRef }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [selectedCard, setSelectedCard] = useState(card || {});
  const [canvasWidth, setCanvasWidth] = useState(400);
  const [canvasHeight, setCanvasHeight] = useState(400); // Make it square for circular canvas
  const [textBox, setTextBox] = useState(null);
  const [canvas, setCanvas] = useState(null);
  const [imageObject, setImageObject] = useState(null);
  const [dataImg, setDataImg] = useState(null);

  useEffect(() => {
    setSelectedCard(card);
  }, [card]);

  useEffect(() => {
    if (!canvasRef.current) return;

    const canvasNew = new fabric.Canvas(canvasRef.current, {
      backgroundColor: selectedCard.backgroundColor || "#000000",
    });
    canvasNew.setWidth(canvasWidth);
    canvasNew.setHeight(canvasHeight);
    
    setCanvas(canvasNew);

    // Create a circular clip path
    const clipPath = new fabric.Circle({
      radius: Math.min(canvasWidth, canvasHeight) / 2,
      originX: 'center',
      originY: 'center',
      left: canvasWidth / 2,
      top: canvasHeight / 2,
    });
    canvasNew.clipPath = clipPath;

   
  
    // Center align canvas
    const containerWidth = canvasRef.current.parentElement.offsetWidth;
    const containerHeight = canvasRef.current.parentElement.offsetHeight;
    const leftOffset = (containerWidth - canvasWidth) / 2;
    const topOffset = (containerHeight - canvasHeight) / 2;
    canvasNew.set({ left: leftOffset, top: topOffset });

    // Load canvas data from local storage
    const textbox = JSON.parse(localStorage.getItem("circletextbox"));
    setTextBox(textbox);
    const imagebox = JSON.parse(localStorage.getItem("circleimageObject"));
    setImageObject(imagebox);
  }, [canvasRef, canvasWidth, canvasHeight, selectedCard]);
 

  useEffect(() => {
    if (!textBox || !canvas) return;

    // Create fabric textbox object
    if (flag === "front") {
      const fabricTextBox = new fabric.Textbox(textBox.text, {
        left: textBox.left,
        top: textBox.top,
        fontFamily: textBox.fontFamily,
        fontSize: textBox.fontSize,
        fill: textBox.fill,
        width: textBox.width,
        height: textBox.height,
        lineHeight: textBox.lineHeight,
        selectable: false,
        visible: textBox.visible,
      });

      // Add textbox to canvas
      canvas.add(fabricTextBox);
    }
  }, [textBox, canvas, flag]);

  useEffect(() => {
    if (!imageObject || !canvas) return;

    // Create fabric image object
    if (flag === "front") {
      fabric.Image.fromURL(imageObject.src, function (img) {
        img.set({
          left: imageObject.left/2,
          top: imageObject.top,
          angle: imageObject.angle,
          scaleX: imageObject.scaleX,
          scaleY: imageObject.scaleY,
          selectable: false,
          visible: imageObject.visible,
        });

        // Add the image object to the canvas
        canvas.add(img);
      });
    }
  }, [imageObject, canvas, flag]);

  useEffect(() => {
    if (getCanvasRef) {
      getCanvasRef(containerRef);
    }
  }, [containerRef, getCanvasRef]);

  return (
    <div style={{ position: "relative" }}>
      <div
        ref={containerRef}
        className="canvas-container d-flex align-content-center justify-content-center mt-3 ms-5"
        style={{
          width: canvasWidth,
          height: canvasHeight,
          borderRadius: '50%',
          overflow: 'hidden',
        }}
      >
        <canvas ref={canvasRef} className="canvas" style={{ borderRadius: '50%' }}></canvas>
       
        {flag === "back" && (
          <img
            src={`${AXIOS_BASE_URL}uploads/${card.background_image}`}
            className="d-flex flex-column align-items-center justify-content-center z-index-1 position-absolute"
            style={{ top: "0%", width: "62%" }}
          />
        )}
        {flag === "front" && card && (
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              top:
                card.nfc_icon_placement === "3" ||
                card.nfc_icon_placement === "4"
                  ? "calc(100% - 107px)"
                  : "45px",
              right: ["1", "3"].includes(card.nfc_icon_placement)
                ? "calc(45%)"
                : "auto",
              left: ["2", "4"].includes(card.nfc_icon_placement)
                ? "135px"
                : "auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ position: "relative", minWidth: 0, flex: 1 }}>
              {/* <input
                className="form-control-plaintext"
                defaultValue={selectedCard.placeholder_text}
                name="placeholder_text"
                onChange={(e) => {
                  setSelectedCard({
                    ...selectedCard,
                    placeholder_text: e.target.value,
                  });
                }}
                dir="rtl"
              /> */}
              <div className="nfc-icon-placement">
                <img src={images.nfcsymbol} style={{ width: "33px" }} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TagCanvas;
