import axios from "axios";
import { GET_PROFILE,PROFILE_ERROR ,UPDATE_PROFILE,AXIOS_BASE_URL, GET_SOCIALDETAILS, GET_GALLERY} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { setAlert } from "./setAlert";
import { loaduser } from "./auth";



export const getCurrentProfile = (id) =>async dispatch =>{
    // console.log(id)
     if (localStorage.token) {
         setAuthToken(localStorage.token);
     }
 try {
     const res = await axios.get(`users/profiles/${id}`)
 console.log(res)
     dispatch({
         type: GET_PROFILE,
         payload:res.data,
     });
 } catch (err) {
     dispatch({
         type: PROFILE_ERROR,
         payload: err.message,
     });
 }
 }

 export const createProfile = (obj)=> async dispatch =>{
    // const config = {
    //     headers: {
    //         'Content-Type': 'application/json'
    //     }
    // }
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    const body = obj;
    try {
        const res =  await axios.post(`users/profiles`, body  )
        console.log(res)
        dispatch(setAlert(res.data.message, 'success'))

        dispatch({
            type: GET_PROFILE,
            payload:res.data,
        });
    } catch (err) {
        dispatch({
            type: PROFILE_ERROR,
            payload: err.message,
        });
    }
}

export const updateProfile = (obj, id)=> async dispatch =>{
      
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    const body = obj;
    console.log(id)
    try {
        const res =  await axios.put(`users/profiles/${id}`, body )
        console.log(res)
        dispatch(setAlert(res.data.message, 'success'))

        dispatch({
            type: UPDATE_PROFILE,
             payload:res.data,
        });
    } catch (err) {
        dispatch({
            type: PROFILE_ERROR,
            payload: err.message,
        });
    }
}


export const updateProfilePic = (body,id)=>async dispatch=>{
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res =  await axios.put(`${AXIOS_BASE_URL}users/imageuploads/profile/profilepic/${id}`,body )
        console.log(res.data.message)
        dispatch(setAlert(res.data.message, 'success'))

        dispatch({
            type: UPDATE_PROFILE,
             payload:res.data,
        });
    } catch (err) {
        dispatch({
            type: PROFILE_ERROR,
            payload: err.message,
        });
    }
 }


 export const updateCoverPic = ( obj,id)=>async dispatch=>{
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    const body = obj;
    console.log(id);
    console.log(body);
    try {
        const res =  await axios.put(`${AXIOS_BASE_URL}users/imageuploads/profile/coverpic/${id}`,body )
        console.log(res.data.message)
        dispatch(setAlert(res.data.message, 'success'))

        dispatch({
            type: UPDATE_PROFILE,
             payload:res.data,
        });
    } catch (err) {
        dispatch({
            type: PROFILE_ERROR,
            payload: err.message,
        });
    }
 }


 export const deleteSocialDetails=(id)=>async dispatch =>{
    console.log(id)
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res =  await axios.delete(`users/profile/delete/socialdetails/${id}` )
        console.log(res.data.message)
        dispatch(setAlert(res.data.message, 'danger'))

        dispatch({
            type: UPDATE_PROFILE,
             payload:res.data,
        });
    } catch (err) {
        // dispatch(setAlert(err.message))
        dispatch({
            type: PROFILE_ERROR,
            payload: err.message,
        });
    }
 }


 export const updateBgTheme = ( body,id)=>async dispatch=>{
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res =  await axios.post(`users/imageuploads/profile/bgtheme/${id}`,body )
        console.log(res.data.message)
        dispatch(setAlert(res.data.message, 'success'))

        dispatch({
            type: UPDATE_PROFILE,
             payload:res.data,
        });
    } catch (err) {
        dispatch({
            type: PROFILE_ERROR,
            payload: err.message,
        });
    }
 }



 export const deleteImages = (id)=>async dispatch=>{
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res =  await axios.delete(`users/profile/delete/images/${id}` )
        console.log(res.data.message)
        dispatch(setAlert(res.data.message, 'danger'))

        dispatch({
            type: UPDATE_PROFILE,
             payload:res.data,
        });
    } catch (err) {
        dispatch({
            type: PROFILE_ERROR,
            payload: err.message,
        });
    }
 }


 export const deleteMultipleImages = (obj)=>async dispatch=>{
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    
    }
    console.log(obj)
    const body = obj;
    console.log(body);
    try {
       
        const res =  await axios.delete(`users/profile/delete/multiple/images`, {  data: body } )
        console.log(res.data.message)
        dispatch(setAlert(res.data.message, 'success'))

        dispatch({
            type: UPDATE_PROFILE,
             payload:res.data,
        });
    } catch (err) {
        dispatch({
            type: PROFILE_ERROR,
            payload: err.message,
        });
    }
 }

 export const getSocialDetails = () => async dispatch =>{
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    
    }
try {
 const res = await axios.get(`users/socialDetails`);
console.log(res);   
dispatch({
    type: GET_SOCIALDETAILS,
     payload:res.data.socialDetails,
});

} catch (error) {
    dispatch({
        type: PROFILE_ERROR,
        payload: error.message,
    }); 
}
 }

 export const getGallery = ()=>async dispatch =>{
    if (localStorage.token) {
        setAuthToken(localStorage.token);
        
    }
    try {
        const res = await axios.get(`users/gallery`);
        console.log(res);
        dispatch({
            type: GET_GALLERY,
             payload: res.data.gallery,
        });  
    } catch (error) {
        dispatch({
            type: PROFILE_ERROR,
            payload: error.message,
    })

 }
}

export const saveSocialLinks =(obj)=> async dispatch =>{
    if (localStorage.token) {
        setAuthToken(localStorage.token);
        
    }
    const val = obj;
    try {
          const res = await axios.post(
            `${AXIOS_BASE_URL}users/socialdetails/`,
            val
          );
         dispatch( setAlert(res.data.message, 'success'))
        
        console.log(res);
        } catch (error) {
          console.log(error);
         dispatch( setAlert(error.message,"danger"))
          
        }
}
export const updateSocialLinkSwap = (socialLinks)=> async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
        
    }
    console.log(socialLinks,"action");
    axios.put('users/socialdetails/swap', { socialLinks: socialLinks })
      .then(response => {
        console.log(response.data); // Log success message
        // Optionally, perform any additional actions upon successful update
      })
      .catch(error => {
        console.error('Error updating social links:', error);
        // Optionally, handle error display or any other actions
      });
  }
export const updateSocialLinks = (obj) => async dispatch =>{
    if (localStorage.token) {
        setAuthToken(localStorage.token);
        
    }
    const val= obj;
    try {
        const res = await axios.put(
          `${AXIOS_BASE_URL}users/socialdetails/${val.id}`,
          val
        );
       dispatch( setAlert(res.data.message, 'success'))
      
      console.log(res);
      } catch (error) {
        console.log(error);
       dispatch( setAlert(error.message,"danger"))
        
      }
}

export const updateUsername =(username) => async dispatch =>{
    if (localStorage.token) {
        setAuthToken(localStorage.token);
        
    }
   
    console.log(username);
    try {
        const res = await axios.put(
            `${AXIOS_BASE_URL}users/username/update` ,
           {username}
          );
        console.log(res.data);
        dispatch(loaduser())
        dispatch(setAlert(res.data.message))
        
    } catch (error) {
        // console.log(error);
        dispatch( setAlert(error.response.data.error,"danger"))
    }
}

export const changePassword = (password) => async dispatch =>{
    if (localStorage.token) {
        setAuthToken(localStorage.token);
        
    }
    try {
        const response = await axios.put('/users/updatepassword', { password });
        console.log(response.data);
        dispatch(loaduser())
        dispatch(setAlert(response.data.message));
       
    } catch (error) {
        dispatch(setAlert("Failed to update password", "danger"));
    }
} 