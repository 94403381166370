import React, { useState, useEffect } from "react";
import Footer from "../../components/layout/Footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { adminlogin } from "../../actions/AdminAuth";
import images from "../../utils/imageImports";
import AdminLoginHeader from "../../components/layout/AdminLoginHeader";
import PasswordVisibilityToggler from "../../utils/passwordVisibilityToggler";
function AdminLogin({
  adminlogin,
  adminauth: { adminIsAuthenticated, adminLoading },
}) {
  const navigate = useNavigate();
  const [passwordInputType, toggleIcon] = PasswordVisibilityToggler();
  const [user, setuser] = useState({
    email: "",
    password: "",
  });
  // console.log(adminIsAuthenticated);
  useEffect(() => {
    if (adminIsAuthenticated) {
      navigate("/admin/dashboard");
    } else {
      navigate("/admin/login");
    }
  }, [adminIsAuthenticated]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(e);
    setuser({ ...user, [name]: value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(user);
    try {
      await adminlogin(user);
      // console.log(user)
      navigate("/admin/dashboard");
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      <AdminLoginHeader />
      <section id="main">
        <div id="signin" className="container position-relative">
          <div className="row">
            <div className="col-md-4 mt-3">
              <form className="mt-5" onSubmit={onSubmit}>
                <div className="welcard p-4 mt-5">
                  <p className="mb-5 mt-2">
                    <img src={images.loginIcon} alt="Login Icon" />
                  </p>
                  <h6>Welcome to VYLDFYRE</h6>
                  <p>Please sign in or sign up below.</p>
                  <div className="text-start mt-4">
                    <div className="mb-4">
                      <input
                        type="email"
                        name="email"
                        value={user.email}
                        onChange={handleChange}
                        className="form-control text-light"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="position-relative mb-4">
                      <input
                        type={passwordInputType}
                        className="form-control text-light"
                        name="password"
                        value={user.password}
                        onChange={handleChange}
                        placeholder="Password"
                        required
                      />
                      <span
                        className="passwordToggle"
                        style={{ color: "white" }}
                      >
                        {toggleIcon}
                      </span>
                    </div>
                    <button type="submit" className="loginbtn my-4 border-0">
                      Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-7 dispnone">
              <img src={images.innerBg} className="img-fluid w-100" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
AdminLogin.propTypes = {
  adminlogin: PropTypes.func.isRequired,
  adminauth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  adminauth: state.adminauth,
});

export default connect(mapStateToProps, { adminlogin })(AdminLogin);
