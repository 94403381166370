import axios from "axios";
import { CARD_SUCCESS, CARD_FAILURE, GET_CARDS, AXIOS_BASE_URL ,DELETE_CARD, UPDATE_CARD} from "./types";
import { setAlert } from "./setAlert";
import setAuthToken from "../utils/setAuthToken";


axios.defaults.baseURL=AXIOS_BASE_URL;


export const submitCard =(formData)=>async dispatch =>{
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    if (localStorage.admin_token) {
        setAuthToken(localStorage.admin_token);
    }
    try {
        const res = await axios.post("api/admin/users/card", formData);
        console.log(res.data);
        dispatch({
            type: CARD_SUCCESS,
            payload: res.data,
        })
      dispatch(setAlert(res.data.message));
    } catch (err) {
        console.log(err)
        dispatch(setAlert(err.response.data.error,'danger'))
        dispatch({
            type: CARD_FAILURE,
            
        })
    }

}

export const getAdminCards =() => async dispatch =>{
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    if (localStorage.admin_token) {
        setAuthToken(localStorage.admin_token);
    }
    
    try {
        const res = await axios.get(`api/admin/users/cards`, config);
        console.log(res.data.data);
        dispatch({
            type: GET_CARDS,
            payload: res.data.data,
        })
    //   dispatch(setAlert(res.data.msg));
    } catch (err) {
        dispatch(setAlert(err.response.data.message,'danger'))
        dispatch({
            type: CARD_FAILURE,
            
        })
    }
}

export const updateCard =(id, formData)=> async dispatch=>{
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    if (localStorage.admin_token) {
        setAuthToken(localStorage.admin_token);
    }
    try {
        const res = await axios.put(`api/admin/users/cards/${id}`,formData );
        console.log(res.data);
        dispatch({
            type: UPDATE_CARD,
            payload: res.data.data,
        })
        dispatch(setAlert(res.data.message));
    } catch (err) {
        dispatch(setAlert(err.response.data.message,'danger'))
        dispatch({
            type: CARD_FAILURE,
            
        })
    }

}


export const deleteCard = (id) => async dispatch  =>{
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    if (localStorage.admin_token) {
        setAuthToken(localStorage.admin_token);
    }
    try {
        const res = await axios.put(`api/admin/users/delete/card/${id}`,config)
        console.log(res.data.message);
        dispatch({
            type: DELETE_CARD,
            payload: res.data.data,
        })
        dispatch(setAlert(res.data.message, "danger"));
        dispatch(getAdminCards);
    } catch (err) {
        dispatch(setAlert(err.response.data.message,'danger'))
        dispatch({
            type: CARD_FAILURE,
            
        })
    }


}