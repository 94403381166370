import React from "react";
import { Routes, Route } from "react-router-dom";
import RoleBasedRoute from "./RoleBasedRoute";

import Miniwebsite from "../components/dashboard/miniwebsite/miniwebsite";
import MiniStore from "../components/dashboard/ministore/ministore";
import Settings from "../components/dashboard/settings/settings";
import Analytics from "../components/dashboard/analytics/analytics";
import BasicDetails from "../components/dashboard/BasicDetails";
import SocialDetails from "../components/dashboard/socialDetails";
import Gallery from "../components/dashboard/Gallery";
import Cart from "../components/shop/Cart";
import Checkout from "../components/shop/Checkout";
import OrderConfirmPage from "../components/shop/OrderConfirmPage";
import Dashboard from "../admin/dasboard/Dashboard";
import CardsList from "../admin/dasboard/CardsList";
import CreateCard from "../admin/dasboard/CreateCard";
import UpdateCard from "../admin/dasboard/UpdateCard";
import Orders from "../admin/dasboard/Orders";
import OrderDetails from "../admin/dasboard/OrderDetails";
import TagList from "../admin/dasboard/TagList";
import CreateTag from "../admin/dasboard/CreateTag";
import BandsList from "../admin/dasboard/BandsList";
import CreateBand from "../admin/dasboard/CreateBand";
import RingsList from "../admin/dasboard/RingsList";
import CreateRing from "../admin/dasboard/CreateRing";

function AuthenticatedRoutes({ userRole }) {
  return (
    <Routes>
      {userRole === "user" ? (
        <>
          <Route path="/profile" element={<RoleBasedRoute element={<BasicDetails />} requiredRole="user" userRole={userRole} />} />
          <Route path="/miniwebsite" element={<RoleBasedRoute element={<Miniwebsite />} requiredRole="user" userRole={userRole} />} />
          <Route path="/analytics" element={<RoleBasedRoute element={<Analytics />} requiredRole="user" userRole={userRole} />} />
          <Route path="/settings" element={<RoleBasedRoute element={<Settings />} requiredRole="user" userRole={userRole} />} />
          <Route path="/ministore" element={<RoleBasedRoute element={<MiniStore />} requiredRole="user" userRole={userRole} />} />
          <Route path="/profile/basicdetails" element={<RoleBasedRoute element={<BasicDetails />} requiredRole="user" userRole={userRole} />} />

          <Route path="/profile/social-details" element={<RoleBasedRoute element={<SocialDetails />} requiredRole="user" userRole={userRole} />} />
          <Route path="/profile/gallery" element={<RoleBasedRoute element={<Gallery />} requiredRole="user" userRole={userRole} />} />
          <Route path="/vyldfyrecard/cart" element={<RoleBasedRoute element={<Cart />} requiredRole="user" userRole={userRole} />} />
          <Route path="/vyldfyrecard/checkout" element={<RoleBasedRoute element={<Checkout />} requiredRole="user" userRole={userRole} />} />
          <Route path="/vyldfyrecard/orderconfirm" element={<RoleBasedRoute element={<OrderConfirmPage />} requiredRole="user" userRole={userRole} />} />
        </>
      ) : (
        <>
          <Route path="/admin/dashboard" element={<RoleBasedRoute element={<Dashboard />} requiredRole="admin" userRole={userRole} />} />
          <Route path="/admin/cardlist" element={<RoleBasedRoute element={<CardsList />} requiredRole="admin" userRole={userRole} />} />
          <Route path="/admin/createcard" element={<RoleBasedRoute element={<CreateCard />} requiredRole="admin" userRole={userRole} />} />
          <Route path="/admin/taglist" element={<RoleBasedRoute element={<TagList />} requiredRole="admin" userRole={userRole} />} />
          <Route path="/admin/createtag" element={<RoleBasedRoute element={<CreateTag />} requiredRole="admin" userRole={userRole} />} />
          <Route path="/admin/bandlist" element={<RoleBasedRoute element={<BandsList />} requiredRole="admin" userRole={userRole} />} />
          <Route path="/admin/createband" element={<RoleBasedRoute element={<CreateBand />} requiredRole="admin" userRole={userRole} />} />
          <Route path="/admin/ringlist" element={<RoleBasedRoute element={<RingsList />} requiredRole="admin" userRole={userRole} />} />
          <Route path="/admin/createring" element={<RoleBasedRoute element={<CreateRing />} requiredRole="admin" userRole={userRole} />} />
          <Route path="/admin/updatecard/:id" element={<RoleBasedRoute element={<UpdateCard />} requiredRole="admin" userRole={userRole} />} />
          <Route path="/admin/orders" element={<RoleBasedRoute element={<Orders />} requiredRole="admin" userRole={userRole} />} />
          <Route path="/admin/orderdetails/:id" element={<RoleBasedRoute element={<OrderDetails />} requiredRole="admin" userRole={userRole} />} />
        </>
      )}
    </Routes>
  );
}

export default AuthenticatedRoutes;
