import React from "react";
import { useState, useEffect } from "react";
import RegisterValidator from "../../utils/registerValidator";
import passwordValidator from "../../utils/passwordValidator";
import { googleSignUp } from "../../actions/auth";
import PropTypes from "prop-types";
import images from "../../utils/imageImports";
import Footer from "../layout/Footer";
import { Link, useNavigate } from "react-router-dom";
import PasswordVisibilityToggler from "../../utils/passwordVisibilityToggler";
import { useGoogleLogin } from "@react-oauth/google";
import { connect } from "react-redux";
import axios from "axios";
import { setAlert } from "../../actions/setAlert";
import { register } from "../../actions/auth";
//import { googleSignUp } from "../../actions/auth";

function SignUp({ register, googleSignUp, setAlert }) {
  const navigate = useNavigate();

  const [passwordInputType, toggleIcon] = PasswordVisibilityToggler();
  const [
    confirmPasswordInputType,
    confirmPasswordToggleIcon,
  ] = PasswordVisibilityToggler();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [value, setValue] = useState({
    password: "",
    confirmPassword: "",
  });
  var [errors, setErrors] = useState();
  const [passErrors, setPassErrors] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const [user, setUser] = useState("");
  // verifies the errorrs //
  useEffect(() => {
    setErrors(RegisterValidator(values));
    setPassErrors(passwordValidator(value));
  }, [values, value]);

  // assign the given values to the variables //

  function handleChange(e) {
    setValues({ ...values, [e.target.name]: e.target.value });
    RegisterValidator(values);
  }
  // handles pasword changes //
  function handleChangePassword(e) {
    setValue({ ...value, [e.target.name]: e.target.value });
    passwordValidator(value);
  }

  // submitting the form //
  const onSubmit = async (e) => {
    if (errors === "") {
      try {
        const res = await axios.post("users/verify", values);
        console.log(res.data);

        setShowPassword(!showPassword);
      } catch (error) {
        setAlert(error.response.data.error, "danger");
      }
    } else {
      setAlert(errors, "danger");
    }
  };
  values.password = value.password;
  // on completing register form //
  const onRegister = async (e) => {
    e.preventDefault();
    console.log(values);
    console.log(value);
    if (passErrors === "") {
      await register(values);
      navigate("/login");
    } else {
      setAlert(passErrors, "danger");
    }
  };
  // initializing google auth //
  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  // handles registration with google //
  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          const email = res.data.email;
          const googleId = res.data.id;
          const is_verified = res.data.verified_email;
          // const lastName = res.data.family_name;
          // const firstName = res.data.given_name;
          const name = res.data.name;
          // const picture = res.data.picture;
          googleSignUp({ email, googleId, is_verified, name });
          console.log(res);
          // navigate('/login');
        })
        .catch((err) => console.log(err));
    }
  }, [user, googleSignUp]);

  return (
    <div>
      <header>
        <div className="container">
          <div className="d-none d-sm-block">
            <div className="row pt-4">
              <div className="col-md-6">
                <Link to="/">
                  <img
                    src={images.logo}
                    className="img-fluid"
                    width="208"
                    alt=""
                  />
                </Link>
              </div>
              <div className="col-md-6 text-end">
                <Link to="/login" className="signbtn">
                  Log in
                </Link>
              </div>
            </div>
          </div>
          {/* <!-- For mobile resposive -->  */}
          <div className="d-md-none">
            <div className="row">
              <div className="col-md-12 pt-3">
                <Link to="/">
                  <img
                    src={images.logo}
                    to="/"
                    width="150"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
                <Link to="/login" className="signbtn">
                  Log in
                </Link>
              </div>
            </div>
          </div>
          {/* <!-- end mobile resposive -->       */}
        </div>
      </header>
      <section id="main">
        <div id="signin" className="container position-relative">
          <div className="row">
            <div className="col-md-4 mt-3">
              <form className="mt-5">
                <div className="welcard p-4 mt-5">
                  {/* <p className="mb-5 mt-2">
                    <img src={images.loginIcon} alt="" />
                  </p> */}
                  <h6 className="mt-4">Welcome to VYLDFYRE</h6>
                  <p>Please sign in or sign up below.</p>
                  <div className="text-start mt-4">
                    <div>
                      {/* <p className="mb-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      name='email'
                      value={values.email}
                      onChange={handleChange}
                      required
                   />     
                  </p> */}
                      <div className="form-item mb-3">
                        <input
                          type="text"
                          id="email"
                          autoComplete="off"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          required
                        />
                        <label htmlFor="email">Email</label>
                      </div>
                      {/* <p className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="username"
                      name='username'
                      value={values.username}
                      onChange={handleChange}
                      required
                   />
                        
                   
                     
                  </p> */}
                      <div className="form-item mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="username"
                          id="username"
                          autoComplete="off"
                          value={values.username}
                          onChange={handleChange}
                          required
                        />
                        <label htmlFor="username">Username</label>
                      </div>
                      {/* <Link onClick={onSubmit} className="loginbtn my-4">
                          Setup Password
                        </Link> */}
                    </div>

                    <div>
                      <div className="form-item">
                        <span
                          className="passwordToggle"
                          style={{
                            top: "32%",
                            left: "93%",
                            color: "white",
                            zIndex: "10",
                          }}
                        >
                          {toggleIcon}
                        </span>
                        <input
                          type={passwordInputType}
                          className="form-control"
                          // placeholder="Password"
                          value={value.password}
                          onChange={handleChangePassword}
                          name="password"
                          required
                        />
                        <label htmlFor="password"> Password</label>
                      </div>
                     
                      <div className="form-item">
                  <span
                    className="passwordToggle"
                    style={{
                      top: "32%",
                      left: "93%",
                      color: "white",
                      zIndex: "10",
                    }}
                  >
                    {confirmPasswordToggleIcon}
                  </span>
                  <input
                    type={confirmPasswordInputType}
                    className="form-control"
                    // placeholder="confirm Password"
                    value={value.confirmPassword}
                    onChange={handleChangePassword}
                    name="confirmPassword"
                    required
                  />
                   <label htmlFor="confirmPassword">Confirm Password</label>
                  </div>
                      <Link onClick={onRegister} className="loginbtn my-4">
                        Create Account
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="googlecard p-4">
                  <Link
                    className="googlebtn my-2"
                    onClick={() => googleLogin()}
                  >
                    <img src={images.googleIcon} className="pe-2" alt="" /> Sign
                    up with Google
                  </Link>
                </div>
                <p className="text-center pt-4 mb-5">
                  already have an account?{" "}
                  <Link to="/login" className="linkyellow1">
                    Log In
                  </Link>
                </p>
              </form>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-7 dispnone">
              <img src={images.innerBg} className="img-fluid w-100" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

SignUp.propTypes = {
  register: PropTypes.func.isRequired,
  googleSignUp: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default connect(null, { register, googleSignUp, setAlert })(SignUp);
