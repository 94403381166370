import React from 'react'
// import { Link } from 'react-router-dom'
import AdminHeader from '../../components/layout/AdminHeader'
import AdminSidebar from '../../components/layout/AdminSidebar'
import Footer from "../../components/layout/Footer"
function Dashboard() {
  return (
    <div>
      <AdminHeader />
      <section className="dashboard">
<div className="container mb-5">        
        <div className="row my-5 g-0">           
           < AdminSidebar />
            <div className="col-md-10">
                <h1>Dashboard</h1>
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </div>           
        </div> 
</div>
</section>

    <Footer />
    </div>
  )
}

export default Dashboard